import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Button, CardActionArea, CardActions} from '@mui/material';

type props = {
    title: string;
    body: string;
    deleteAction : (integration_id: number) => void
    id: number
}

const MultiActionAreaCard = ({id, title, body, deleteAction}: props) => {
    return (
        <Card sx={{maxWidth: 250, width: '100%'}}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="140"
                    image="/integrations.jpeg"
                    alt="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {body}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="error" onClick={() => {deleteAction(id)}}>
                    Delete
                </Button>
            </CardActions>
        </Card>
    );
}

export default MultiActionAreaCard;