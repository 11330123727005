import React from "react";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

type Props = {
    crumbs: {
        name: string;
        path: string;
    }[];
};

function BreadCrumbs({ crumbs }: Props) {
    const navigate = useNavigate();
    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            marginBottom={"1rem"}
            fontSize={12}
            color={"rgba(0, 0, 0, 0.5)"}
        >
            {crumbs.map((crumb: { path: string; name: string }, index) => {
                const lastLoop = index === crumbs.length - 1;
                return (
                    <Box
                        key={crumb.path}
                        display={"flex"}
                        flexDirection={"row"}
                        flexWrap={"nowrap"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Link
                            key={"a" + crumb.path}
                            href={crumb.path}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                navigate(crumb.path);
                            }}
                            sx={{
                                textDecoration: "none",
                                color: lastLoop
                                    ? "rgba(0,0,0,0.75)"
                                    : "rgba(0,0,0,0.5)",
                                "&:hover": {
                                    cursor: "pointer",
                                    transition: "0.3s ease-out",
                                    color: lastLoop
                                        ? "rgba(0,0,0,1)"
                                        : "rgba(0,0,0,0.75)",
                                },
                            }}
                        >
                            {crumb.name}
                        </Link>
                        {!lastLoop && (
                            <Box
                                marginLeft={1}
                                marginRight={1}
                                color={"rgba(0,0,0,0.5)"}
                            >
                                /
                            </Box>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
}

export default BreadCrumbs;
