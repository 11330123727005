import React from 'react';
import './styles.scss';
import Input from "../../components/input";
import {fieldProps} from "../../types/create_account";

type Props = {
    email: string,
    password: string,
    confirmPassword: string,
    firstName: string,
    lastName: string,
    setEmail: (email: string) => void;
    setPassword: (password: string) => void;
    setConfirmPassword: (confirmPassword: string) => void;
    setFirstName: (firstName: string) => void;
    setLastName: (lastName: string) => void;
};

interface Step1Props {
    fields: fieldProps;
}

function Step1({fields}: Step1Props) {

    return (
        <>
            <p className={'page-header-class'}>Create Account </p>
            <p className={'step-class'}> Step 1 of 2 </p>

            {fields.map((field) =>
                (
                    <Input value={field.value} name={field.name} placeholder={field.placeholder}
                           validation={field.validation ? field.validation : undefined}
                           type={field.type ? field.type: undefined}
                           onChange={(name: string, value) => {
                               field.setValue(value)
                           }}></Input>
                )
            )}

        </>
    );
}

export default Step1;
