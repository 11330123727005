import {validationSchema} from "../../../types/new_project";


const baseValidation: validationSchema[] = [
    {
        fieldName: 'name',
        title: 'Name',
        validationRule: '^.{1,}$',
        errorMessage: 'Project name is required'
    },
    {
        fieldName: 'imagesPath',
        title: 'Images Path',
        validationRule: '^.{1,}$', // Validation rule for Databricks Workspace URL
        errorMessage: 'Images Path is required.'
    },
    {
        fieldName: 'detectionsPath',
        title: 'Inference Results Path',
        validationRule: '^.{1,}$',
        errorMessage: 'Inference Results Path is required.'
    },
    {
        fieldName: 'groundTruthsPath',
        title: 'Labels Path',
        validationRule: '^.{1,}$',
        errorMessage: 'Labels Path is required.'
    },
]

export const validationDetection: validationSchema[] = [
    ...baseValidation,
    {
        fieldName: 'detections_metadata_format',
        title: 'Inference Metadata Format',
        validationRule: '^.{1,}$', // Validation rule for Databricks Workspace URL
        errorMessage: 'Inference Metadata Format is required.'
    },

    {
        fieldName: 'ground_truths_format',
        title: 'Labels Format',
        validationRule: '^.{1,}$',
        errorMessage: 'Labels Format is required.'
    },

];


export const validationSegmentation: validationSchema[] = [
    ...baseValidation,
]

export const validationClassification: validationSchema[] = [
    ...baseValidation,
]