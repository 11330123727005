import React from "react";
import "./styles.scss";
import InputWithTitle from "../../components/input_with_title";
import InputWithImage from "../../components/input_with_image";
import Button from "../../components/button";
import {useNavigate} from "react-router";
import {validation} from "./validations/databricks_validation";


function DatabricksImport({
                              newProject,
                              setProjectData,
                              createProject,
                              errors,
                              setError,
                              toggleOptions,
                              loading,
                              integration

                          }: any) {
    const navigate = useNavigate();




    const validate = () => {
        let valid = true;
        validation.forEach((field) => {
            if (field.validationRule) {
                const regex = new RegExp(field.validationRule);
                let target = newProject[field.fieldName] ? newProject[field.fieldName] : ""
                if (!regex.test(target)) {
                    setError({
                        key: field.fieldName,
                        type: 'validationError',
                        message: field.errorMessage ? field.errorMessage : `Invalid ${field.title}`
                    })
                    valid = false
                }
            }
        })
        return valid
    }


    return (<>

            <div className={"input-group-class"}>
                <p className={"input-group-title-class"}>
                    Input Paths
                </p>
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        textAlign: "center",
                    }}
                >
                    Paths should point to test images, model inference
                    results on those images, and their respective
                    labels.
                </p>

                <InputWithImage
                    subtitle={""}
                    size={"long"}
                    type={"text"}
                    title={"Databricks Workspace URL"}
                    validation={"^.{1,}$"}
                    name={"host"}
                    placeholder={"Databricks Workspace URL"}
                    imagePath={"/image.svg"}
                    onChange={setProjectData}
                ></InputWithImage>
                <InputWithImage
                    subtitle={""}
                    size={"long"}
                    type={"text"}
                    title={"Databricks cluster id"}
                    validation={"^.{1,}$"}
                    name={"cluster_id"}
                    placeholder={"Databricks cluster id"}
                    imagePath={"/image.svg"}
                    onChange={setProjectData}
                    tooltipText={"The cluster ID: Cluster ID is the unique ID for a cluster in Databricks. To get the cluster ID, click the Clusters tab in the left pane and then select a cluster name. You can find the cluster ID in the URL of this page <databricks-url>/#/settings/clusters/<cluster-id>/configuration."}
                ></InputWithImage>
                <InputWithImage
                    subtitle={""}
                    size={"long"}
                    type={"text"}
                    title={"Catalog name"}
                    validation={"^.{1,}$"}
                    name={"catalogue_name"}
                    placeholder={"Catalog name of project data"}
                    imagePath={"/image.svg"}
                    onChange={setProjectData}
                ></InputWithImage>
                <InputWithImage
                    subtitle={""}
                    size={"long"}
                    type={"text"}
                    title={"Schema name"}
                    validation={"^.{1,}$"}
                    name={"schema_name"}
                    placeholder={"Schema name of project data"}
                    imagePath={"/image.svg"}
                    onChange={setProjectData}
                ></InputWithImage>
                <InputWithImage
                    subtitle={""}
                    size={"long"}
                    type={"text"}
                    title={"Table name"}
                    validation={"^.{1,}$"}
                    name={"table_name"}
                    placeholder={"Table name of project data"}
                    imagePath={"/image.svg"}
                    onChange={setProjectData}
                ></InputWithImage>
                <InputWithTitle
                    title={"Classes"}
                    size={"long"}
                    placeholder={["Class Id", "Class Name"]}
                    type={"dict-input"}
                    name={"classes"}
                    onChange={setProjectData}
                ></InputWithTitle>
            </div>
            <div className={"button-wrapper-class"}>
                <Button
                    loading={loading}
                    text={"Back"}
                    onClick={() => {
                        navigate("/add-project");
                    }}
                ></Button>
                <Button
                    text={"Create Project"}
                    onClick={async () => {
                        validate() && await createProject(newProject, navigate);

                    }}
                ></Button>
            </div>
        </>
    );
}


export default DatabricksImport;
