import { startCase } from "lodash";
import React from "react";
import { useNavigate } from "react-router";

type Props = {
  task?: string,
  id?: number,
  status: string,
  name: string,
  message: string
}

function Notification({ task, id, status, name, message }: Props) {
  const navigate = useNavigate();
  return (<div onClick={() => {
      navigate(`/${task}s/${id}`)
    }} className={`notification-class ${status}`}>
      <img alt={''} src={`../${status}_icon.svg`}/>
      <a onLoad={() => {
      }}
      >{task && startCase(task)}
        <span style={{ "fontWeight": "bold" }}> {name} </span> {message}
      </a>
    </div>
  )
}

export default Notification