import React from 'react';
import './styles.scss';

const UnsupportedScreen = () => {
  return (
    <div className={"not-supported-screen-class"}>
      <h1>This page is not supported on smaller screens.</h1>
      <h2>Please view this content on a larger device for the best experience.</h2>
    </div>
  );
};

export default UnsupportedScreen;
