import React, {useEffect, useState} from "react";
import "./styles.scss";
import InputWithTitle from "../../components/input_with_title";
import InputWithImage from "../../components/input_with_image";
import Button from "../../components/button";
import NewProject, {validationSchema} from "../../types/new_project";
import {useNavigate} from "react-router";
import {validationClassification, validationDetection, validationSegmentation} from "./validations/s3_validation";


function S3Import({
                      newProject,
                      setProjectData,
                      createProject,
                      errors,
                      setError,
                      setIgnoreLabelId,
                      metadataOptions,
                      labelsOptions,
                      setIgnoreLabel,
                      ignoreLabel,
                      loading,
                      integration,
                      clearProjectData
                  }: any) {
    const navigate = useNavigate();

    const validate = () => {
        let validation;
        switch (newProject.task) {
            case 'detection':
                validation = validationDetection;
                break
            case 'segmentation':
                validation = validationSegmentation;
                break
            case 'classification':
                validation = validationClassification
                break
        }
        let valid = true;
        validation && validation.forEach((field) => {
            if (field.validationRule) {
                const regex = new RegExp(field.validationRule);
                let target = newProject[field.fieldName] ? newProject[field.fieldName] : ""
                if (!regex.test(target)) {
                    setError({
                        key: field.fieldName,
                        type: 'validationError',
                        message: field.errorMessage ? field.errorMessage : `Invalid ${field.title}`
                    })
                    valid = false
                }
            }
        })
        return valid
    }


    return (
        <>
            <div className={"input-group-class"}>
                <p className={"input-group-title-class"}>
                    Input Paths
                </p>
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        textAlign: "center",
                    }}
                >
                    Paths should point to test images, model inference
                    results on those images, and their respective
                    labels.
                </p>
                <InputWithImage
                    value={newProject.imagesPath ? newProject.imagesPath : ''}
                    subtitle={"(png, jpg, jpeg)"}
                    size={"long"}
                    type={"text"}
                    title={"Images Path"}
                    validation={"^.{1,}$"}
                    name={"imagesPath"}
                    placeholder={"/path/to/images"}
                    imagePath={"/image.svg"}
                    onChange={setProjectData}
                ></InputWithImage>
                {newProject.task == "detection" && (
                    <InputWithTitle
                        size={"long"}
                        type={"select"}
                        title={"Inference Metadata Format"}
                        name={"detections_metadata_format"}
                        placeholder={"Select Format"}
                        options={metadataOptions}
                        onChange={setProjectData}
                    ></InputWithTitle>
                )}

                {newProject.task == "detection" && (
                    <InputWithImage
                        value={newProject.detectionsPath ? newProject.detectionsPath : ''}
                        subtitle={"(txt)"}
                        size={"long"}
                        type={"text"}
                        title={"Inference Results Path"}
                        name={"detectionsPath"}
                        validation={"^.{1,}$"}
                        placeholder={"/path/to/inference_results"}
                        imagePath={"/ground_truth.svg"}
                        onChange={setProjectData}
                    ></InputWithImage>
                )}
                {newProject.task == "detection" && (
                    <InputWithTitle
                        size={"long"}
                        type={"select"}
                        title={"Labels Format"}
                        name={"ground_truths_format"}
                        value={newProject.groundTruthsFormat}
                        placeholder={"Select Format"}
                        options={labelsOptions}
                        onChange={setProjectData}
                    ></InputWithTitle>
                )}

                {newProject.task == "detection" && (
                    <InputWithImage
                        value={newProject.groundTruthsPath ? newProject.groundTruthsPath : ''}
                        subtitle={"(txt, xml, json)"}
                        size={"long"}
                        type={"text"}
                        name={"groundTruthsPath"}
                        validation={"^.{1,}$"}
                        title={"Labels Path"}
                        placeholder={"/path/to/labels"}
                        imagePath={"/detections.svg"}
                        onChange={setProjectData}
                    ></InputWithImage>
                )}

                {newProject.task == "segmentation" && (
                    <InputWithImage
                        value={newProject.detectionsPath ? newProject.detectionsPath : ''}
                        subtitle={"(png)"}
                        size={"long"}
                        type={"text"}
                        title={"Inference Results Path"}
                        name={"detectionsPath"}
                        validation={"^.{1,}$"}
                        placeholder={"/path/to/inference_results"}
                        imagePath={"/ground_truth.svg"}
                        onChange={setProjectData}
                    ></InputWithImage>
                )}
                {newProject.task == "segmentation" && (
                    <InputWithImage
                        value={newProject.groundTruthsPath ? newProject.groundTruthsPath : ''}
                        subtitle={"(png)"}
                        size={"long"}
                        type={"text"}
                        name={"groundTruthsPath"}
                        validation={"^.{1,}$"}
                        title={"Labels Path"}
                        placeholder={"/path/to/labels"}
                        imagePath={"/detections.svg"}
                        onChange={setProjectData}
                    ></InputWithImage>
                )}
                {newProject.task == "classification" && (
                    <InputWithImage
                        value={newProject.detectionsPath ? newProject.detectionsPath : ''}
                        subtitle={"(txt)"}
                        size={"long"}
                        type={"text"}
                        title={"Inference Results Path"}
                        name={"detectionsPath"}
                        validation={"^.{1,}$"}
                        placeholder={"/path/to/inference_results"}
                        imagePath={"/ground_truth.svg"}
                        onChange={setProjectData}
                    ></InputWithImage>
                )}

                {newProject.task == "classification" && (
                    <InputWithImage
                        value={newProject.groundTruthsPath ? newProject.groundTruthsPath: ''}
                        subtitle={"(txt)"}
                        size={"long"}
                        type={"text"}
                        name={"groundTruthsPath"}
                        validation={"^.{1,}$"}
                        title={"Labels Path"}
                        placeholder={"/path/to/labels"}
                        imagePath={"/detections.svg"}
                        onChange={setProjectData}
                    ></InputWithImage>
                )}

                <InputWithTitle
                    title={"Classes"}
                    size={"long"}
                    placeholder={["Class Id", "Class Name"]}
                    type={"dict-input"}
                    name={"classes"}
                    onChange={setProjectData}
                ></InputWithTitle>
                {newProject.task == "segmentation" && (
                    <InputWithTitle
                        placeholder={""}
                        onChange={(key, value) => {
                            setIgnoreLabel(value);
                        }}
                        title={"Ignore Label"}
                        name={"ignoreLabel"}
                        type={"checkbox"}
                    ></InputWithTitle>
                )}

                {newProject.task == "segmentation" && ignoreLabel && (
                    <InputWithTitle
                        title={"Ignore label ID"}
                        size={"long"}
                        placeholder={"Ignore label ID"}
                        name={"ignoreLabel"}
                        onChange={setIgnoreLabelId}
                    ></InputWithTitle>
                )}

                <div style={{marginTop: "3rem"}}></div>
                <InputWithTitle
                    placeholder={""}
                    onChange={setProjectData}
                    title={"Generate insights using Gen AI (Beta)"}
                    name={"generate_insights"}
                    type={"checkbox"}
                    disabled={true}
                ></InputWithTitle>
                <InputWithTitle
                    placeholder={""}
                    onChange={setProjectData}
                    title={"Get insights from PieData"}
                    name={"use_datalake"}
                    type={"checkbox"}
                ></InputWithTitle>
            </div>
            <div className={"button-wrapper-class"}>
                <Button
                    loading={loading}
                    text={"Back"}
                    onClick={() => {
                        navigate("/");
                    }}
                ></Button>
                <Button
                    loading={loading}
                    text={"Create Project"}
                    onClick={async () => {
                        validate() && await createProject(newProject, navigate);
                    }}
                ></Button>
            </div>
        </>

    );
}


export default S3Import;
