import React, { useEffect } from "react";
import "./page_wrapper.scss";
import { connect } from "react-redux";
import BreadCrumbs from "../components/breadcrumbs";
import { getNotificationsAction } from "../actions/page";
import Notification from "../components/notification";
import { useLocation, useNavigate } from "react-router";
import AuthErrors from "../components/auth_errors";
import { getUserAction } from "../actions/user";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

type Props = {
    children: any;
    title: string;
    description: string;
    breadcrumbs: any;
    notifications: [];
    getNotifications: () => void;
    errors: [];
    getUser: () => void;
};

function PageWrapper({
    children,
    title,
    description,
    breadcrumbs,
    notifications,
    getNotifications,
    errors,
    getUser,
}: Props) {
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const projectToken = searchParams.get("project_token");
        if (!projectToken) {
            getNotifications();
            getUser();
        }
    }, []);
    const location = useLocation();
    //@ts-ignore
    const navigate = useNavigate();
    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                }}
            >
                <Box>
                    <BreadCrumbs crumbs={breadcrumbs}></BreadCrumbs>
                </Box>
                <Typography
                    variant="h3"
                    fontSize={32}
                    lineHeight={1}
                    fontWeight={"500"}
                >
                    {title}
                </Typography>
                <Typography
                    variant="subtitle1"
                    paddingTop={1}
                    fontSize={14}
                    lineHeight={1.5}
                    color={"rgba(0,0,0,0.75)"}
                >
                    {description}
                </Typography>
                <Box marginTop={2}>{children}</Box>
            </Box>
            <Box className={"notifications-wrapper-class"}>
                <AuthErrors></AuthErrors>
                {notifications.map(
                    (
                        notification: {
                            task?: string;
                            id?: number;
                            status: string;
                            name: string;
                            message: string;
                        },
                        index: number
                    ) => {
                        return (
                            <Notification
                                task={notification.task}
                                id={notification.id}
                                status={notification.status}
                                name={notification.name}
                                message={notification.message}
                            ></Notification>
                        );
                    }
                )}
            </Box>
        </Box>
    );
}

const mapStateToProps = (state: any) => ({
    breadcrumbs: state.breadcrumbs,
    notifications: state.notifications,
    errors: state.errors,
});
const mapDispatchToProps = (dispatch: any) => ({
    getNotifications: async () => {
        dispatch(await getNotificationsAction());
    },
    getUser: async () => dispatch(await getUserAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper);
