import React from "react";
import {map} from "lodash";
import "./styles.scss";
import moment from "moment";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";

type Props = {
    data: object[];
    tableConfig: object[];
    onRowClick: (object: any) => void;
    sharable?: boolean;
    share?: (id: number, selected: boolean) => void;
};
const statusTexts = {
    finished: "Finished",
    started: "Running",
    failure: "Failed",
};

function Table({data, tableConfig, onRowClick, sharable, share}: Props) {
    return (
        <>
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={"column"}
                border={"1px solid #D9D9DB"}
                borderRadius={"6px"}
            >
                <Box
                    key={0}
                    borderTop={0}
                    fontSize={14}
                    color={"rgba(0,0,0,0.75)"}
                    fontWeight={500}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    height={"3.6rem"}
                >
                    {map(tableConfig, (config: any) => {
                        return (
                            <Box
                                key={config.name}
                                flex={1}
                                marginLeft={"1rem"}
                                display={"flex"}
                                justifyContent={"start"}
                            >
                                {config.name}
                            </Box>
                        );
                    })}
                    {sharable && (
                        <Box
                            key={"share"}
                            flex={1}
                            marginLeft={"1rem"}
                            display={"flex"}
                            justifyContent={"start"}
                        >
                            Select
                        </Box>
                    )}
                </Box>
                {data &&
                    data.map((row: any) => (
                        <Box
                            key={row.id}
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            borderTop={"1px solid #D9D9DB"}
                            height={"3.6rem"}
                            fontSize={14}
                            color={"rgba(0,0,0,0.75)"}
                            sx={{
                                cursor: "pointer",
                                "&:hover": {
                                    backgroundColor: "rgba(221,207,223, 0.4)",
                                    transition: "0.25s ease-out",
                                },
                            }}
                            onClick={() => {
                                onRowClick(row);
                            }}
                        >
                            {map(tableConfig, (config: any) => {
                                let value = row[config.key];
                                if (config.type === "date") {
                                    value = moment
                                        .utc(value)
                                        .local()
                                        .format("MM/DD/YYYY HH:mm");
                                }

                                return (
                                    <Box
                                        key={value}
                                        flex={1}
                                        marginLeft={"1rem"}
                                        display={"flex"}
                                        justifyContent={"start"}
                                    >
                                        {config.key === "status" ? (
                                            <span
                                                className={`status-class status-${value}-class`}
                                            >
                                                <div style={{display: 'flex'}}>
                                                {/*@ts-ignore*/}
                                                    {statusTexts[value]}
                                                    &nbsp;{/* Non-breaking space */}
                                                    {value == "failure" && (
                                                        <img
                                                            className={"info-class"}
                                                            alt={""}
                                                            src={`/retry.svg`}
                                                            onClick={() => {
                                                                if (
                                                                    config["action"]
                                                                ) {
                                                                    config[
                                                                        "action"
                                                                        ](row.id);
                                                                }
                                                            }}
                                                        ></img>
                                                    )}
                                                    </div>
                                            </span>
                                        ) : (
                                            value
                                        )}
                                    </Box>
                                );
                            })}
                            {sharable && (
                                <Box
                                    flex={1}
                                    marginLeft={"1rem"}
                                    display={"flex"}
                                    justifyContent={"start"}
                                >
                                    <input
                                        type={"checkbox"}
                                        onClick={(event: any) => {
                                            event.stopPropagation();
                                            //@ts-ignore
                                            share(
                                                row.id,
                                                event.currentTarget.checked
                                            );
                                        }}
                                        className={"table-share-class"}
                                    ></input>
                                </Box>
                            )}
                        </Box>
                    ))}
            </Box>
        </>
    );
}

export default Table;
