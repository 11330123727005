import { each, isObject, snakeCase } from 'lodash'

export const snakeCaseKeys = (obj: object) => {
  let newObj: any;

  if (Array.isArray(obj)) {
    newObj = [];
    each(obj, val => {
      if (isObject(val)) {
        newObj.push(snakeCaseKeys(val));
      } else {
        newObj.push(val);
      }
    });
  } else {
    newObj = {};
    each(obj, (val, key) => {
      if (isObject(val)) {
        newObj[snakeCase(key)] = snakeCaseKeys(val);
      } else {
        newObj[snakeCase(key)] = val;
      }
    });
  }

  return newObj;
};