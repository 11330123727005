import {doRequest} from "../helpers/request_helper";

const getUserAction = async () => {
    const response: Response = await doRequest('get', `api/v1/user/`)
    let user = await response.json()

    if (response.status === 200) {
        return {
            type: 'GET_USER',
            payload: user
        }

    } else if (response.status === 404) {
        return {
            type: 'SET_ERRORS',
            payload: {}
        }

    }
}

const setCredentialsAction = (name: string, value: string) => {
    return {
        type: 'SET_CREDENTIALS',
        payload: {name, value}
    }
}
const clearCredentialsAction = () => {
    return {
        type: 'CLEAR_CREDENTIALS',
        payload: null
    }
}
const updateDataProviderCredentialsAction = async (newCredentials: any) => {

    const response: Response = await doRequest('post', `api/v1/user/data_provider`, newCredentials)
    return {
        type: "UPDATE_DATA_PROVIDER",
        payload: null
    }
}

const getIntegrations = async () => {
    const response: Response = await doRequest('get', `api/v1/user/integrations`)
    let integrations = await response.json()

    if (response.status === 200) {
        return {
            type: 'GET_INTEGRATIONS',
            payload: integrations
        }

    } else if (response.status === 404) {
        return {
            type: 'SET_ERRORS',
            payload: {}
        }

    }
}

const deleteIntegration = async (integration_id: number) => {
    const response: Response = await doRequest('delete', `api/v1/user/integrations/${integration_id}`)

    if (response.status === 200) {
        return {
            type: 'DELETE_INTEGRATION',
            payload: integration_id
        }
    }
}


export {
    getUserAction,
    setCredentialsAction,
    updateDataProviderCredentialsAction,
    clearCredentialsAction,
    getIntegrations,
    deleteIntegration,
}

