import React, { ReactComponentElement, useState } from "react";
// import "./styles.scss";
import { signOut } from "../../actions/auth";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";

type Props = {
    user: any;
    element: ReactComponentElement<any>;
};

const drawerWidth = 212;

const navDict: {
    [key: string]: {
        icon: string;
        path: string;
        internal: boolean;
        disabled: boolean;
    };
} = {
    // Dashboard: {icon: "/dashboard.svg", path: "/"},
    Projects: {
        icon: "/projects.svg",
        path: "/",
        internal: true,
        disabled: false,
    },
    Integrations: {
        icon: "/integrations.svg",
        path: "/integrations",
        internal: true,
        disabled: false,
    },
    Team: {
        icon: "/team.svg",
        path: "/team",
        internal: true,
        disabled: true,
    },
    Docs: {
        icon: "/docs.svg",
        path: "https://docs.manot.ai",
        internal: false,
        disabled: false,
    },
};

const NavBar = ({ user, element }: Props) => {
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const projectToken = searchParams.get("project_token");
    const pathname = useLocation().pathname;

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchor(null);
    };

    const isOpen = Boolean(anchor);

    return (
        <Box
            sx={{
                display: "flex",
                height: "100vh",
                background:
                    "linear-gradient(180deg, #27273A, #27273A, #27273A, #27273A, #4A2853)",
            }}
        >
            {user && (
                <>
                    <CssBaseline />
                    <Drawer
                        variant="permanent"
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: {
                                width: drawerWidth,
                                boxSizing: "border-box",
                                backgroundColor: "initial",
                            },
                        }}
                    >
                        <Toolbar variant="dense" />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "space-between",
                                height: "100vh",
                            }}
                        >
                            <Box width={"100%"}>
                                <Box
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                    sx={{
                                        "&:hover": { cursor: "pointer" },
                                        paddingLeft: 3,
                                        paddingBottom: 3,
                                    }}
                                >
                                    <img
                                        alt={"Manot logo"}
                                        src="/manot_logo.svg"
                                        width="112px"
                                    />
                                </Box>

                                <List sx={{ width: "100%" }}>
                                    {Object.keys(navDict).map((key, index) => {
                                        const value =
                                            navDict[
                                                key as keyof typeof navDict
                                            ];

                                        return (
                                            <ListItem key={key} disablePadding>
                                                <ListItemButton
                                                    disableGutters
                                                    disableRipple
                                                    disabled={value.disabled}
                                                    selected={
                                                        value.path === pathname
                                                    }
                                                    onClick={() => {
                                                        value.internal
                                                            ? navigate(
                                                                  value.path
                                                              )
                                                            : window.open(
                                                                  value.path,
                                                                  "_blank"
                                                              );
                                                    }}
                                                    sx={{
                                                        width: "100%",
                                                        paddingLeft: 3,
                                                        paddingTop: 0.75,
                                                        paddingBottom: 0.75,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent:
                                                            "flex-start",
                                                        "&:hover": {
                                                            backgroundColor:
                                                                "rgba(221,207,223, 0.035)",
                                                        },
                                                        "&.Mui-selected": {
                                                            backgroundColor:
                                                                "rgba(221,207,223, 0.05)",
                                                            "&:hover": {
                                                                backgroundColor:
                                                                    "rgba(221,207,223, 0.075)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={{
                                                            minWidth: "auto",
                                                            paddingRight: 2,
                                                        }}
                                                    >
                                                        <img
                                                            alt={key + "_icon"}
                                                            style={{
                                                                height: "18px",
                                                                width: "18px",
                                                            }}
                                                            src={value.icon}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={key}
                                                        sx={{
                                                            color: "rgba(255, 255, 255, 0.8)",
                                                        }}
                                                    />
                                                    {value.disabled && (
                                                        <Chip
                                                            label="on the way!"
                                                            size="small"
                                                            variant="outlined"
                                                            sx={{
                                                                color: "rgba(255, 255, 255, 0.8)",
                                                                borderColor:
                                                                    "rgba(255, 255, 255, 0.8)",
                                                                marginRight: 3,
                                                                marginTop:
                                                                    "2px",
                                                                opacity: 0.75,
                                                                height: "20px",
                                                                fontSize:
                                                                    "0.75rem",
                                                            }}
                                                        />
                                                    )}
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                            {projectToken && (
                                <Box
                                    onClick={() => {
                                        navigate(
                                            `/sign-in?project_token=${projectToken}`
                                        );
                                    }}
                                >
                                    Sign In
                                </Box>
                            )}
                            <Box paddingBottom={3} width={"100%"}>
                                <>
                                    <IconButton
                                        onClick={handleMenuOpen}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={
                                            isOpen ? "account-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                            isOpen ? "true" : undefined
                                        }
                                    >
                                        <img
                                            alt={"Profile icon"}
                                            src="/profile.svg"
                                        />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchor}
                                        id="account-menu"
                                        open={isOpen}
                                        onClose={handleMenuClose}
                                        onClick={handleMenuClose}
                                        // sx={{
                                        //     backgroundColor
                                        // }}
                                        transformOrigin={{
                                            horizontal: "left",
                                            vertical: "bottom",
                                        }}
                                        anchorOrigin={{
                                            horizontal: "left",
                                            vertical: "top",
                                        }}
                                        sx={{
                                            "& .MuiPaper-root": {
                                                backgroundColor: "#f9f9f9",
                                                color: "rgba(0,0,0,0.75)",
                                            },
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                handleMenuClose();
                                                window.open(
                                                    "https://forms.gle/7qM1RswEb8ebPHCU9",
                                                    "_blank"
                                                );
                                            }}
                                        >
                                            <ListItemIcon>
                                                <img
                                                    alt={"contact_icon"}
                                                    style={{
                                                        height: "18px",
                                                        width: "18px",
                                                    }}
                                                    src={"/contact.svg"}
                                                />
                                            </ListItemIcon>
                                            Contact Us
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleMenuClose();
                                                navigate("/account");
                                            }}
                                        >
                                            <ListItemIcon>
                                                <img
                                                    alt={"settings_icon"}
                                                    style={{
                                                        height: "18px",
                                                        width: "18px",
                                                    }}
                                                    src={"/settings.svg"}
                                                />
                                            </ListItemIcon>
                                            Settings
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleMenuClose();
                                                signOut();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <img
                                                    alt={"logout_icon"}
                                                    style={{
                                                        height: "18px",
                                                        width: "18px",
                                                    }}
                                                    src={"/log_out.svg"}
                                                />
                                            </ListItemIcon>
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                </>
                            </Box>
                        </Box>
                    </Drawer>
                </>
            )}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 6,
                    borderTopLeftRadius: 6,
                    background: "#F4F3F5",
                    overflowY: "scroll",
                }}
            >
                {element}
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: any) => ({
    user: state.user,
});
const mapDispatchToProps = (dispatch: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
