import React, { useEffect, useState } from 'react';
import Input from "../../components/input";
import Button from "../../components/button";
import { useSearchParams } from 'react-router-dom';
import { updatePassword, verifyPasswordToken } from "../../actions/auth";
import { connect } from "react-redux";
import './styles.scss';
import AuthErrors from "../../components/auth_errors";

type Props = {
  updatePasswordAction: (password: string, confirmPassword: string, token: string|null) => void;
}

function ResetPassword({ updatePasswordAction }: Props) {
  const [tokenValid, setTokenValid] = useState<boolean | null>(null);
  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get('token');
  useEffect(() => {
    const validateToken = async () => {
      const tokenCorrect = await verifyPasswordToken(resetPasswordToken);
      setTokenValid(tokenCorrect)
    }
    validateToken()
  }, [resetPasswordToken]);


  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  return (<div className={'auth-container-class'}>
      {tokenValid === null && (<p className={'page-header-class'}>Loading</p>)}
      {tokenValid === true && <>
          <p className={'page-header-class'}>Reset Password</p>
          <Input type={'password'}
                 name={'password'}
                 placeholder={'New Password'} onChange={(key: string, value) => {
            setPassword(value)
          }}/>
          <Input type={'password'} placeholder={'Confirm Password'}
                 name={'confirm_password'}
                 onChange={(key: string, value) => {
                   setConfirmPassword(value)
                 }}/>
          <Button text={'Reset Password'} onClick={async() => {
            await updatePasswordAction(password, confirmPassword, resetPasswordToken)
          }
          }/>
      </>}
      {tokenValid === false && (<>
        <p className={'page-header-class'}>Token Expired Or Invalid</p>
        <div><a className={'sing-in-class'} href={'/sign-in'}>Sign in </a></div>
      </>)}
      <AuthErrors/>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
});
const mapDispatchToProps = (dispatch: any) => ({
  updatePasswordAction: async (password: string, confirmPassword: string, token: string|null) => {
    dispatch(await updatePassword(password, confirmPassword, token))
  }
});


export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
