import React from 'react';
import {connect} from "react-redux";

type Props = {
    errors: [];
}

function AuthErrors({errors}: Props) {
    return (<div className={"notifications-wrapper-class"}>{
        errors.map((error: { message: string, type: string }) => {
            // if (error.type == 'validationError') {
            //     return null;
            // }
            return (<div className={`notification-class failure`}>
                <img alt={''} src={`../failure_icon.svg`}/>
                <a> <span
                    style={{"fontWeight": "bold"}}> {error.message}
              </span>
                </a>
            </div>)
        })
    }
    </div>)
}

const mapStateToProps = (state: any) => ({
    errors: state.errors
});
const mapDispatchToProps = (dispatch: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AuthErrors);