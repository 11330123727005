import React, {useRef} from 'react';
import './styles.scss';
import {uploadEvaluationFilesAction, uploadProjectFilesAction} from "../../actions/upload_files";
import {connect} from "react-redux";
import {addNotification, removeNotifications} from "../../actions/notifications";

export type Props = {
    type: string;
    dataProvider: string;
    task?: string;
    setRef: any;
    uploadProjectFilesAction: (dataProvider: string, files: any, task?: string,) => void
    setLoading?: (loading: boolean) => void
    uploadEvaluationFilesAction: (dataProvider: string, files: any, task?: string,) => void
    onClick?: () => void
    addNotification: (notification_text: string, name: string, status: string) => void,
    removeNotifications: () => void

};

function FileUpload({
                        type,
                        dataProvider,
                        setRef,
                        uploadProjectFilesAction,
                        task,
                        uploadEvaluationFilesAction,
                        setLoading,
                        onClick,
                        addNotification,
                        removeNotifications
                    }: Props) {
    const ref = useRef(null)
    setRef(ref);
    return (
        <div style={{"display": "none"}}>
            {
                //@ts-ignore
                <input ref={ref} type={'file'} directory="" webkitdirectory="" multiple mozdirectory=""
                       onChange={async (event) => {
                           onClick && onClick()
                           if (type == 'project') {
                               //@ts-ignore
                               if (setLoading) {
                                   setLoading(true)
                               }
                               await uploadProjectFilesAction(dataProvider, event.target.files, task,)
                               if (setLoading) {
                                   setLoading(false)
                               }
                               addNotification("Data upload complete!", '', 'finished')
                               setTimeout(() => {
                                   removeNotifications();
                               }, 5000)
                           } else if (type == 'evaluation') {
                               if (setLoading) {
                                   setLoading(true)
                               }
                               await uploadEvaluationFilesAction(dataProvider, event.target.files)
                               if (setLoading) {
                                   setLoading(false)
                               }
                               addNotification("Data upload complete!", '', 'finished')
                               setTimeout(() => {
                                   removeNotifications();
                               }, 5000)
                           }
                       }
                       }></input>
            }
        </div>);
}

const mapDispatchToProps = (dispatch: any) => (
    {
        uploadProjectFilesAction: async (dataProvider: string, files: any, task?: string) => dispatch(await uploadProjectFilesAction(dataProvider, files, task)),
        uploadEvaluationFilesAction: async (dataProvider: string, files: any) => dispatch(await uploadEvaluationFilesAction(dataProvider, files)),
        addNotification: (notification_text: string, message: string, status: string) => {
            (dispatch(addNotification(notification_text, message, status)))
        },
        removeNotifications: () => (dispatch(removeNotifications())),
    })

export default connect(null, mapDispatchToProps)(FileUpload);
