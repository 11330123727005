import React, { useEffect } from "react";
import "./styles.scss";
import Table from "../../components/table";
import { getProjectsAction, retryProjectAction } from "../../actions/projects";
import { connect } from "react-redux";
import Loading from "../../components/loading";
import Project from "../../types/project";
import { useNavigate } from "react-router";
import { setBreadcrumbs } from "../../actions/page";
import Card from "../../components/card";

type Props = {
    getProjects: () => void;
    setBreadcrumbs: (breadcrumbs: any[]) => void;
    projects: null | Project[];
    publicProjects: null | Project[];
    sharedProjects: null | Project[];
    retryProject: (projectId: number) => void;
};

const Projects = ({
    getProjects,
    setBreadcrumbs,
    projects,
    publicProjects,
    sharedProjects,
    retryProject,
}: Props) => {
    const navigate = useNavigate();
    const addProject = () => {
        navigate("/add-project");
    };

    const crumbs = [
        {
            name: "Projects",
            path: "/",
        },
    ];

    useEffect(() => {
        getProjects();
        setBreadcrumbs(crumbs);
    }, []);

    if (
        projects === null ||
        publicProjects === null ||
        sharedProjects === null
    ) {
        return <Loading></Loading>;
    }

    const tableConfig = [
        { name: "Project Name", key: "name", type: "string" },
        { name: "Project Type", key: "provider_type", type: "string" },
        { name: "Number of Images", key: "number_of_images", type: "string" },
        { name: "Task", key: "task", type: "string" },
        { name: "Created At", key: "created_at", type: "date" },
        {
            name: "Status",
            key: "status",
            type: "progress",
            action: async (id: number) => {
                await retryProject(id);
            },
        },
    ];
    return (
        <>
            <Card
                title={"My Projects"}
                buttonText={"New Project"}
                buttonAction={addProject}
                buttonIcon={
                    <img
                        width={18}
                        height={18}
                        alt="plus icon"
                        src={"/new.svg"}
                    />
                }
                infoText="Here are projects you have created. Create another project by clicking 'New Project'."
            >
                <Table
                    data={projects}
                    tableConfig={tableConfig}
                    onRowClick={(project: any) => {
                        if (project.status === "finished") {
                            navigate(`/projects/${project.id}`);
                        }
                    }}
                ></Table>
            </Card>
            <Card
                title={"Shared Projects"}
                infoText="Here are projects shared with you. Have a project owner share an existing project by using your email."
            >
                <Table
                    data={sharedProjects}
                    tableConfig={tableConfig}
                    onRowClick={(project: any) => {
                        if (project.status === "finished") {
                            navigate(`/projects/${project.id}`);
                        }
                    }}
                ></Table>
            </Card>

            <Card
                title={"Demo Projects"}
                infoText="Here are Manot's demo projects. Play around with different project examples."
            >
                <Table
                    data={publicProjects}
                    tableConfig={tableConfig}
                    onRowClick={(project: any) => {
                        if (project.status === "finished") {
                            navigate(`/projects/${project.id}`);
                        }
                    }}
                ></Table>
            </Card>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    projectName: state.newProject.projectName,
    newProject: state.newProject,
    projects: state.projects,
    publicProjects: state.publicProjects,
    sharedProjects: state.sharedProjects,
});

const mapDispatchToProps = (dispatch: any) => ({
    setBreadcrumbs: (breadcrumbs: any[]) => {
        dispatch(setBreadcrumbs(breadcrumbs));
    },
    getProjects: async () => {
        dispatch(await getProjectsAction());
    },
    retryProject: async (projectId: number) => {
        dispatch(await retryProjectAction(projectId));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
