import InputWithTitle from "../../components/input_with_title";
import Box from "@mui/material/Box";
import React from "react";
import S3Modal from "./providers/s3_provider"
import RoboflowModal from "./providers/roboflow";
import DatabricksModal from "./providers/databricks";
import {clearCredentialsAction, setCredentialsAction} from "../../actions/user";
import {connect} from "react-redux";

type props = {
    setCredentials: (name: string, value: string) => void;
    clearCredentials: () => void;
    newCredentials: any;
}


const AddDataProviderIntegration = ({
                                        setCredentials,
                                        newCredentials,
                                        clearCredentials,
                                    }: props
) => {

    return (
        <Box className={"credentials-wrapper"}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                <InputWithTitle
                    placeholder={"Data Provider"}
                    onChange={setCredentials}
                    title={"Data Provider"}
                    name={"type"}
                    options={[
                        {label: "S3", value: "s3"},
                        {label: "Databricks", value: "databricks",},
                    ]}
                    type={"select"}
                    extraActionOnChange={clearCredentials}
                ></InputWithTitle>
            </div>
                {newCredentials.type == "s3" && (
                    <S3Modal/>
                )}
                {newCredentials.type == "roboflow" && (
                    <RoboflowModal/>
                )}
                {newCredentials.type == "databricks" && (
                    <DatabricksModal/>
                )}
        </Box>


)
}

const mapStateToProps = (state: any) => ({
    newCredentials: state.newCredentials,
});

const mapDispatchToProps = (dispatch: any) => ({
    setCredentials: (name: string, value: string) =>
        dispatch(setCredentialsAction(name, value)),
        clearCredentials
:
    () => dispatch(clearCredentialsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDataProviderIntegration);