import {validationSchema} from "../../../types/new_project";

export const validation: validationSchema[] = [
    {
        fieldName: 'name',
        title: 'Name',
        validationRule: '^.{1,}$',
        errorMessage: 'Project name is required'
    },
    {
        fieldName: 'host',
        title: 'Databricks Workspace URL',
        validationRule: '^.{1,}$', // Validation rule for Databricks Workspace URL
        errorMessage: 'Databricks Workspace URL is required.'
    },
    {
        fieldName: 'cluster_id',
        title: 'Databricks cluster id',
        validationRule: '^.{1,}$', // Validation rule for Databricks cluster id
        errorMessage: 'Databricks cluster id is required.'
    },
    {
        fieldName: 'catalogue_name',
        title: 'Catalog name',
        validationRule: '^.{1,}$', // Validation rule for Workspace catalogue name
        errorMessage: 'Catalog name is required.'
    },
    {
        fieldName: 'schema_name',
        title: 'Schema name',
        validationRule: '^.{1,}$', // Validation rule for Workspace schema name
        errorMessage: 'Schema name is required.'
    },
    {
        fieldName: 'table_name',
        title: 'Table name',
        validationRule: '^.{1,}$', // Validation rule for Databricks table name
        errorMessage: 'Table name is required.'
    },

];