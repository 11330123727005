import {
    axisRight,
    max,
    min,
    range,
    scaleBand,
    scaleLinear,
    select,
    selection,
} from "d3";

var margin = { top: 15, right: 30, bottom: 100, left: 150 };

function Matrix(options) {
    let width = Math.max(options.labels.length * 40, 200),
        height = Math.max(options.labels.length * 40, 200),
        data = options.data,
        container = options.container,
        labelsData = options.labels,
        startColor = options.start_color,
        middleColor = options.middle_color,
        endColor = options.end_color;

    let widthLegend = 70;

    let maxValue = max(data, function (layer) {
        return max(layer, function (d) {
            if (typeof d == "string") {
                return 0;
            }
            return d;
        });
    });
    let minValue = min(data, function (layer) {
        return min(layer, function (d) {
            if (typeof d == "string") {
                return 0;
            }
            return d;
        });
    });
    let numrows = data.length;
    let numcols = data[0].length;

    let svg = select(container).append("svg");

    svg = svg
        .attr("id", "container_svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    let x = scaleBand(range(numcols), [0, width]);

    let y = scaleBand(range(numrows), [0, height]);
    let sl = scaleLinear(
        [minValue, (maxValue - minValue) / 2, maxValue],
        [startColor, middleColor, endColor]
    );
    let colorMap = (d) => {
        let selectedColor;
        if (typeof d == "string") {
            selectedColor = sl(0);
        } else {
            selectedColor = sl(d);
        }
        return selectedColor;
    };
    const defs = svg.append("defs");

    defs.append("clipPath")
        .attr("id", "top-left")
        .append("rect")
        .attr("x", 0)
        .attr("width", x(1) + 10)
        .attr("height", x(1) + 10)
        .attr("rx", 10)
        .attr("ry", 10);

    defs.append("clipPath")
        .attr("id", "bottom-left")
        .append("rect")
        .attr("x", 0)
        .attr("y", -10)
        .attr("width", x(1) + 10)
        .attr("height", x(1) + 10)
        .attr("rx", 10)
        .attr("ry", 10);

    defs.append("clipPath")
        .attr("id", "top-right")
        .append("rect")
        .attr("x", -10)
        .attr("y", 0)
        .attr("width", x(1) + 10)
        .attr("height", x(1) + 10)
        .attr("rx", 10)
        .attr("ry", 10);

    defs.append("clipPath")
        .attr("id", "bottom-right")
        .append("rect")
        .attr("x", -10)
        .attr("y", -10)
        .attr("width", x(1) + 10)
        .attr("height", x(1) + 10)
        .attr("rx", 10)
        .attr("ry", 10);

    let row = svg
        .selectAll(".row")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "row")
        .attr("transform", (d, i) => {
            return "translate(0," + y(i) + ")";
        });

    let cell = row
        .selectAll(".cell")
        .data((d) => {
            return d;
        })
        .enter()
        .append("g")
        .attr("class", "cell")
        .attr("transform", (d, i) => {
            return "translate(" + x(i) + ", 0)";
        });
    const rect = cell
        .append("rect")
        .attr("width", x.bandwidth())
        .attr("height", y.bandwidth())
        .style("borderRadius", "1px")
        .style("stroke-width", 0);

    selection.prototype.first = () => {
        return select(this[0][0]);
    };

    row.filter((_, i) => i === 0)
        .select("rect")
        .attr("clip-path", "url(#top-left)");
    row.filter((_, i) => i === row.size() - 1)
        .select("rect")
        .attr("clip-path", "url(#bottom-left)");
    row.filter((_, i) => i == 0)
        .selectAll(".cell")
        .filter((_, i) => i == row.size() - 1)
        .select("rect")
        .attr("clip-path", "url(#top-right)");
    row.filter((_, i) => i == row.size() - 1)
        .selectAll(".cell")
        .filter((_, i) => i == row.size() - 1)
        .select("rect")
        .attr("clip-path", "url(#bottom-right)");

    cell.append("text")
        .attr("font-size", "12px")
        .attr("dy", ".32em")
        .attr("x", x.bandwidth() / 2)
        .attr("y", y.bandwidth() / 2)
        .attr("text-anchor", "middle")
        .style("fill", (d, i) => {
            return d >= maxValue / 2 ? "white" : "black";
        })
        .text(function (d, i) {
            return d;
        });

    row.selectAll(".cell")
        .data(function (d, i) {
            return data[i];
        })
        .style("fill", colorMap);

    let labels = svg.append("g").attr("class", "labels");

    let columnLabels = labels
        .selectAll(".column-label")
        .data(labelsData)
        .enter()
        .append("g")
        .attr("class", "column-label")
        .attr("transform", function (d, i) {
            return "translate(" + x(i) + "," + height + ")";
        });

    columnLabels
        .append("line")
        .style("stroke", "black")
        .style("stroke-width", "1px")
        .attr("x1", x.bandwidth() / 2)
        .attr("x2", x.bandwidth() / 2)
        .attr("y1", 0)
        .attr("y2", 5);

    columnLabels
        .append("text")
        .attr("font-size", "12px")
        .attr("x", 0)
        .attr("y", y.bandwidth() / 2)
        .attr("dy", ".22em")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-60)")
        .text(function (d, i) {
            return d;
        });

    let rowLabels = labels
        .selectAll(".row-label")
        .data(labelsData)
        .enter()
        .append("g")
        .attr("class", "row-label")
        .attr("transform", function (d, i) {
            return "translate(" + 0 + "," + y(i) + ")";
        });

    rowLabels
        .append("line")
        .style("stroke", "black")
        .style("stroke-width", "1px")
        .attr("x1", 0)
        .attr("x2", -5)
        .attr("y1", y.bandwidth() / 2)
        .attr("y2", y.bandwidth() / 2);

    rowLabels
        .append("text")
        .attr("font-size", "12px")
        .attr("x", -x.bandwidth() / 2)
        .attr("y", y.bandwidth() / 2 - x(1) / 2)
        .attr("dy", ".32em")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-45)")
        .text(function (d, i) {
            return d;
        });

    let key = select(".gradient-class")
        .append("svg")
        .attr("width", widthLegend)
        .attr("height", height + margin.top + margin.bottom);

    let legend = key
        .append("defs")
        .append("svg:linearGradient")
        .attr("id", "gradient")
        .attr("x1", "100%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "100%")
        .attr("spreadMethod", "pad");

    legend
        .append("stop")
        .attr("offset", "0%")
        .attr("stop-color", endColor)
        .attr("stop-opacity", 1);
    legend
        .append("stop")
        .attr("offset", "50%")
        .attr("stop-color", middleColor)
        .attr("stop-opacity", 1);

    legend
        .append("stop")
        .attr("offset", "100%")
        .attr("stop-color", startColor)
        .attr("stop-opacity", 1);

    key.append("rect")
        .attr("width", 20)
        .attr("height", height)
        .style("fill", "url(#gradient)")
        .attr("transform", "translate(0," + margin.top + ")");

    let yaxis = scaleLinear([minValue, maxValue], [height, 0]);

    let yAxis = axisRight(yaxis);

    key.append("g")
        .attr("class", "y axis")
        .attr("transform", "translate(22," + margin.top + ")")
        .call(yAxis);
    return true;
}

export default Matrix;
