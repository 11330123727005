import React, { useEffect, useState } from "react";
import "./styles.scss";
import Table from "../../components/table";
import {
    clearProjectAction,
    getProjectAction,
    retryEvaluationAction,
    shareEvaluationAction,
} from "../../actions/project";
import { useParams, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { setBreadcrumbs } from "../../actions/page";
import { isEmpty } from "lodash";
import Loading from "../../components/loading";
import Project from "../../types/project";
import Button from "../../components/button";
import ReactModal from "react-modal";
import InputWithTitle from "../../components/input_with_title";
import { useNavigate } from "react-router";
import humanizeString from "humanize-string";
import ConfusionMatrix from "../../components/confusion_matrix";
import Box from "@mui/material/Box";
import Card from "../../components/card";

type Props = {
    setBreadcrumbs: (breadcrumbs: any[]) => void;
    project: Project;
    getProject: (projectId: string, projectToken: string) => void;
    shareEvaluation: (evaluationIds: number[], emails: string[]) => void;
    retryEvaluation: (evaluationId: number) => void;
    clearProject: () => void;
};

const ProjectPage = ({
    setBreadcrumbs,
    project,
    getProject,
    shareEvaluation,
    retryEvaluation,
    clearProject,
}: Props) => {
    const { projectId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const crumbs = [
        {
            name: "Projects",
            path: "/",
        },
        {
            name: project.name,
            path: `/projects/${project.id}`,
        },
    ];
    useEffect(() => {
        if (!isEmpty(project)) {
            setBreadcrumbs(crumbs);
        }
    }, [project]);

    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        clearProject();
        //@ts-ignore

        getProject(projectId, searchParams.get("project_token"));
    }, []);
    const addEvaluation = () => {
        //@ts-ignore
        navigate(`/projects/${project.id}/add-evaluation`);
    };
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    if (isEmpty(project)) {
        return <Loading />;
    }

    const tableConfig = [
        { name: "Dataset", key: "name", type: "string" },
        { name: "Number Of Images", key: "number_of_images", type: "string" },
        { name: "Created At", key: "created_at", type: "date" },
        {
            name: "Identified Insights",
            key: "extracted_images",
            type: "string",
        },
        {
            name: "Status",
            key: "status",
            type: "progress",
            action: async (id: number) => {
                await retryEvaluation(id);
            },
        },
    ];

    if (!project.name) {
        return null;
    }

    const selectEvaluation = (id: number, selected: boolean) => {
        if (selected) {
            setSelectedIds(selectedIds.concat([id]));
        } else {
            const indexOfSelected = selectedIds.indexOf(id);
            selectedIds.splice(indexOfSelected, 1);
            setSelectedIds([...selectedIds]);
        }
    };
    let emails: string[] = [];
    const setEmails = (name: string, value: string[]) => {
        emails = value;
    };

    const MetricCard = ({
        title,
        children,
    }: {
        title: string;
        children: string;
    }) => (
        <Box
            border={"1px solid #D9D9DB"}
            borderRadius={"6px"}
            p={2}
            marginRight={2}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            fontSize={14}
        >
            <Box paddingBottom={1} fontWeight={500}>
                {title}
            </Box>
            <Box>{children}</Box>
        </Box>
    );
    //@ts-ignore
    return (
        <>
            {project.metrics &&
                !Object.values(project.metrics).every(
                    (v: any) => v == null
                ) && (
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        flexWrap={"wrap"}
                        columnGap={5}
                    >
                        <Box flex={1}>
                            <Card
                                title={"Confusion Matrix"}
                                infoText="Here you can see a representation of your model's accuracy. It showcases the True Positives, True Negatives, False Positives, and False Negatives."
                            >
                                <Box
                                    border={"1px solid #D9D9DB"}
                                    borderRadius={"6px"}
                                    p={2}
                                >
                                    <ConfusionMatrix
                                        project={project}
                                    ></ConfusionMatrix>
                                </Box>
                            </Card>
                        </Box>

                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            flex={1}
                            flexWrap={"wrap"}
                            columnGap={5}
                        >
                            <Box flex={1}>
                                <Card
                                    title={"Metrics"}
                                    infoText="Here are scores related to the performance of your model."
                                >
                                    <Box maxWidth={"fit-content"}>
                                        <Box
                                            display={"flex"}
                                            flexDirection={"row"}
                                        >
                                            {(project.task ==
                                                "classification" ||
                                                project.task ==
                                                    "detection") && (
                                                <MetricCard title="F1 Score">
                                                    {project.metrics[
                                                        "f1score"
                                                    ].toFixed(2)}
                                                </MetricCard>
                                            )}
                                            {project.task ==
                                                "classification" && (
                                                <>
                                                    <MetricCard title="Accuracy (ACC)">
                                                        {project.metrics[
                                                            "accuracy"
                                                        ].toFixed(2)}
                                                    </MetricCard>
                                                    <MetricCard title="Specificity (TNR)">
                                                        {project.metrics[
                                                            "tnr"
                                                        ].toFixed(2)}
                                                    </MetricCard>
                                                </>
                                            )}
                                            {(project.task ==
                                                "classification" ||
                                                project.task ==
                                                    "detection") && (
                                                <>
                                                    <MetricCard title="Precision (PPV)">
                                                        {project.metrics[
                                                            "precision"
                                                        ].toFixed(2)}
                                                    </MetricCard>
                                                    <MetricCard title="Recall">
                                                        {project.metrics[
                                                            "recall"
                                                        ].toFixed(2)}
                                                    </MetricCard>
                                                </>
                                            )}
                                            {(project.task == "segmentation" ||
                                                project.task == "detection") &&
                                                project.metrics["miou"] && (
                                                    <>
                                                        <MetricCard title="mIOU">
                                                            {project.metrics[
                                                                "miou"
                                                            ].toFixed(2)}
                                                        </MetricCard>
                                                    </>
                                                )}
                                        </Box>
                                    </Box>
                                </Card>
                            </Box>
                            <Box flex={1}>
                                {project.type !== "public" &&
                                    !isEmpty(project.execution_args) &&
                                    Object.values(project.execution_args).some(
                                        (v) => v
                                    ) && (
                                        <Card
                                            title={"Project Details"}
                                            infoText="Here are details about this project and integration."
                                        >
                                            <Box
                                                overflow={"auto"}
                                                maxWidth={"fit-content"}
                                                fontSize={14}
                                            >
                                                {project.description && (
                                                    <Box>
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "500",
                                                            }}
                                                        >
                                                            {humanizeString(
                                                                "Description"
                                                            )}
                                                        </span>
                                                        : {project.description}
                                                    </Box>
                                                )}
                                                {Object.keys(
                                                    project.execution_args
                                                ).map((key: string) => {
                                                    //@ts-ignore
                                                    if (
                                                        !project.execution_args[
                                                            key
                                                        ] ||
                                                        key === "classes"
                                                    ) {
                                                        return null;
                                                    }
                                                    return (
                                                        <Box key={key}>
                                                            {/*@ts-ignore*/}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        "500",
                                                                }}
                                                            >
                                                                {humanizeString(
                                                                    key
                                                                )}
                                                            </span>
                                                            :{" "}
                                                            {
                                                                project
                                                                    .execution_args[
                                                                    key
                                                                ]
                                                            }
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                        </Card>
                                    )}
                            </Box>
                        </Box>
                    </Box>
                )}

            <Card
                title={"Evaluations"}
                infoText="Upload a new dataset to see where model blindspots are and how to fix them."
                buttonText={
                    project.access_type != "read" &&
                    project.provider_type != "huggingface"
                        ? "Add Evaluation"
                        : ""
                }
                buttonAction={addEvaluation}
            >
                <>
                    <Table
                        data={project.evaluations}
                        tableConfig={tableConfig}
                        onRowClick={(row) => {
                            if (row.status === "finished") {
                                navigate(`/evaluations/${row.id}`);
                            }
                        }}
                        sharable={project.access_type === "write"}
                        share={selectEvaluation}
                    ></Table>
                    {project.access_type === "write" && (
                        <Box
                            marginTop={4}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            <Button
                                disabled={selectedIds.length === 0}
                                text={"Share"}
                                onClick={() => {
                                    if (selectedIds.length) {
                                        setModalOpen(true);
                                    }
                                }}
                            ></Button>
                        </Box>
                    )}
                </>
            </Card>
            <ReactModal
                onRequestClose={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setModalOpen(false);
                }}
                isOpen={modalOpen}
                shouldCloseOnOverlayClick={true}
                parentSelector={() => document.body}
                className={"share-modal-class"}
            >
                <Box>
                    <InputWithTitle
                        placeholder={"Select Emails"}
                        onChange={setEmails}
                        title={"Type a new email or select a previous"}
                        name={"email_to_share"}
                        type={"multiselect"}
                    ></InputWithTitle>
                    <Box marginTop={3} width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                        <Button
                            text={"Share"}
                            onClick={async () => {
                                await shareEvaluation(selectedIds, emails);
                                setModalOpen(false);
                            }}
                        ></Button>
                    </Box>
                </Box>
            </ReactModal>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    project: state.project,
});

const mapDispatchToProps = (dispatch: any) => ({
    setBreadcrumbs: (breadcrumbs: any[]) => {
        dispatch(setBreadcrumbs(breadcrumbs));
    },
    getProject: async (projectId: string, projectToken: string) => {
        dispatch(await getProjectAction(projectId, projectToken));
    },
    shareEvaluation: async (evaluationIds: number[], emails: string[]) => {
        dispatch(await shareEvaluationAction(evaluationIds, emails));
    },
    retryEvaluation: async (evaluationId: number) => {
        dispatch(await retryEvaluationAction(evaluationId));
    },
    clearProject: () => {
        dispatch(clearProjectAction());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
