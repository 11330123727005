import { doRequest } from "../helpers/request_helper";

const uploadProjectFilesAction = async (data_provider: string, files: FileList | null, task?: string,) => {
  const response: Response = await doRequest('post', 'api/v1/data/upload-files', {
    data_provider: data_provider,
    task: task,
    type: 'project'
  }, {}, null, files)


  if (response && response.status === 200) {
    const res = await response.json()
    return {
      type: 'PROJECT_FILES_UPLOADED',
      payload: res,
    };

  } else {
    const res = await response.json();
    let errors = [];
    if (res.message) {
      errors.push({
        'type': 'error',
        'message': res.message
      })
    } else {
      errors = res.errors
    }


    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: errors
    }
  }
}
const uploadEvaluationFilesAction = async (data_provider: string, files: FileList | null) => {
  const response: Response = await doRequest('post', 'api/v1/data/upload-files', {
    data_provider: data_provider,
    type: 'evaluation'
  }, {}, null, files)
  const res = await response.json()
  return {
    type: 'EVALUATION_FILES_UPLOADED',
    payload: res,
  };

}


export { uploadProjectFilesAction, uploadEvaluationFilesAction }