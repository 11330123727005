import React from "react";
import "./styles.scss";
import Button from "../../components/button";
import { useNavigate } from "react-router";

function NotFound() {
    const navigate = useNavigate();
    const handleSubmit = async () => {
        navigate("/");
    };

    return (
        <div className={"not-found-page-class"}>
            <div className={"not-found-class"}>
                <div className={"not-found-code-class"}>404</div>
                <h1>Page Not Found</h1>
                <p>Sorry, the page you’re looking for doesn’t exist</p>
                <Button text={"Return Home"} onClick={handleSubmit} />
            </div>
        </div>
    );
}

export default NotFound;
