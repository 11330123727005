import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "../button";

type Props = {
    buttonText?: string;
    buttonAction?: () => void;
    buttonIcon?: JSX.Element;
    infoText?: string;
    title: string;
    children: JSX.Element;
};

const Card = ({
    buttonText,
    buttonIcon,
    infoText,
    title,
    buttonAction,
    children,
    ...rest
}: Props) => {
    return (
        <Box
            marginTop={5}
            p={4}
            sx={{
                backgroundColor: "#fff",
                borderRadius: "6px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
            }}
        >
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                marginBottom={4}
            >
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                >
                    <Typography
                        fontSize={16}
                        fontWeight={"500"}
                        paddingRight={1}
                    >
                        {title}
                    </Typography>
                    <Tooltip arrow title={infoText} placement="top">
                        <IconButton
                            disableFocusRipple
                            disableRipple
                            sx={{
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: "initial",
                                },
                            }}
                        >
                            <img
                                width={16}
                                height={16}
                                alt={"info icon"}
                                src={`/info.svg`}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>
                {buttonText && buttonAction && (
                    <Button
                        text={buttonText}
                        icon={buttonIcon}
                        onClick={() => {
                            buttonAction();
                        }}
                    />
                )}
            </Box>
            {children}
        </Box>
    );
};

export default Card;
