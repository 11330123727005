import React, { ReactElement } from "react";
import "./App.scss";
import SignIn from "./pages/sign_in";
import SignUp from "./pages/sign_up";
import NavBar from "./components/nav_bar";
import { Route, Routes } from "react-router-dom";
import AuthenticatedRoute from "./authenticated_route";
import ForgotPassword from "./pages/forgot_passsword";
import ResetPassword from "./pages/reset_password";
import { signOut } from "./actions/auth";
import Projects from "./pages/projects";
import Project from "./pages/project";
import AddProject from "./pages/add_project";
import AddEvaluation from "./pages/add_evaluation";
import Evaluation from "./pages/evaluation";
import S3Import from "./pages/add_project/s3_import";
import Account from "./pages/account";
import PageWrapper from "./pages/page_wrapper";
import NotFound from "./pages/not_found";
import GoogleSignIn from "./pages/google_sign_in";
import UnsupportedScreen from "./components/unsupported_screen";
import posthog from "posthog-js";
import RoboflowImport from "./pages/add_project/roboflow_import";
import DatabricksImport from "./pages/add_project/databricks_import";
import Integrations from "./pages/integrations";

posthog.init("phc_sHU1fRPagL5cBr4b6FYHKqF85uKNVlYLM4JJvGw2r1v", {
    api_host: "https://app.posthog.com",
});

const routes: {
    path: string;
    element: any;
    title: string;
    description: string;
}[] = [
    {
        path: "/",
        element: <Projects />,
        title: "Projects",
        description:
            "Your central hub for all projects. Check out our docs to get started.",
    },
    {
        path: "/integrations",
        element: <Integrations />,
        title: "Integrations",
        description:
            "Here you can integrate with your data provider and/or your labeling solution.",
    },
    {
        path: "/account",
        element: <Account />,
        title: "Settings",
        description: "View your account details here.",
    },
    {
        path: "/add-project",
        element: <AddProject />,
        title: "Add Project",
        description: "To add a project, first select a data provider below.",
    },
    {
        path: "/add-project/s3",
        element: <S3Import />,
        title: "Add Project - S3",
        description:
            "Begin by integrating your S3 bucket or uploading your dataset manually. Check out our docs for a step-by-step breakdown.",
    },
    {
        path: "/add-project/roboflow",
        element: <RoboflowImport />,
        title: "Add Project - Roboflow",
        description:
            "Begin by integrating your Robowflow project. Check out our docs for a step-by-step breakdown.",
    },
    {
        path: "/add-project/databricks",
        element: <DatabricksImport />,
        title: "Add Project - Databricks",
        description:
            "Begin by integrating your DataBrick project. Check out our docs for a step-by-step breakdown",
    },
    {
        path: "/projects/:projectId",
        element: <Project />,
        title: "Project Overview",
        description:
            "Here you can view and manage your project. To dive deeper, upload an evaluation dataset and explore Manot's insights.",
    },
    {
        path: "/projects/:projectId/add-evaluation",
        element: <AddEvaluation />,
        title: "Add Evaluation",
        description:
            "Begin by selecting which data provider you would like to use and then upload the dataset you wish to evaluate.",
    },
    {
        path: "/evaluations/:evaluationId",
        element: <Evaluation />,
        title: "Evaluation",
        description:
            "Here you can understand the blindspots of your model and where improvements can be made.",
    },
];

const App = () => {
    return /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent) ? (
        <UnsupportedScreen />
    ) : (
        <>
            <NavBar
                element={
                    <Routes>
                        <Route path="/sign-in" element={<SignIn />} />
                        <Route
                            path="/sign-in/google"
                            element={<GoogleSignIn />}
                        />
                        <Route path="/sign-up" element={<SignUp />} />
                        <Route
                            path="/forgot-password"
                            element={<ForgotPassword />}
                        />
                        <Route
                            path="/reset-password"
                            element={<ResetPassword />}
                        />
                        <Route path="/not-found" element={<NotFound />} />
                        <Route path="/" element={<AuthenticatedRoute />}>
                            {routes.map((value, index) => {
                                return (
                                    <Route
                                        key={index}
                                        path={value.path}
                                        element={
                                            <PageWrapper
                                                title={value.title}
                                                description={value.description}
                                            >
                                                {value.element}
                                            </PageWrapper>
                                        }
                                    />
                                );
                            })}
                            <Route
                                path="/test"
                                element={
                                    <div
                                        onClick={() => {
                                            signOut();
                                        }}
                                    >
                                        test route
                                    </div>
                                }
                            />
                        </Route>
                        <Route path={"*"} element={<NotFound />} />
                    </Routes>
                }
            />
        </>
    );
};

export default App;
