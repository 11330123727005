import InputWithTitle from "../../../components/input_with_title";
import React from "react";
import {setCredentialsAction} from "../../../actions/user";
import {connect} from "react-redux";
import Box from "@mui/material/Box";


type props = {
    setCredentials: (name: string, value: string) => void;
}


const RoboflowModal = ({setCredentials}: props) => {
    return (
        <Box paddingTop={2}>
            <InputWithTitle
                placeholder={"Name"}
                onChange={setCredentials}
                title={"Name your integration"}
                name={"name"}
            ></InputWithTitle>
            <InputWithTitle
                placeholder={"Roboflow API key"}
                onChange={setCredentials}
                title={"Roboflow API key "}
                name={"api_key"}
            ></InputWithTitle>
        </Box>

    )
}

const mapStateToProps = (state: any) => ({
    newCredentials: state.newCredentials,
});

const mapDispatchToProps = (dispatch: any) => ({
    setCredentials: (name: string, value: string) =>
        dispatch(setCredentialsAction(name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoboflowModal)