import React, {useEffect, useMemo, useRef, useState} from 'react';
import ReactModal from 'react-modal';
import {doRequest} from "../../helpers/request_helper";

type Props = {
    clusterIndex: number;
    index: number;
    data: any;
    loadSimilar: (clusterIndex: number, image_id: number) => void;
    clusterImageBoxes: number[][];
    clusterColor: any,
    task: string,
    showSeeMore: boolean,
    onHover(imageId: number | null): void;
}

function ImageWrapper({
                          clusterIndex,
                          index,
                          data,
                          loadSimilar,
                          onHover,
                          clusterImageBoxes,
                          clusterColor,
                          task,
                          showSeeMore,
                      }: Props) {

    const loaderSVGPath = '/motion-blur.svg'

    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [test, setTest] = useState(false)
    const [imageData, setImageData] = useState(loaderSVGPath);
    const [thumbnailData, setThumbnailData] = useState(loaderSVGPath);

    const imgRef = useRef(null);
    const getImage = async (thumbnail: boolean, tbd: string, id: string) => {
        if (thumbnail) {
            if (tbd !== loaderSVGPath) return
        } else {
            if (id !== loaderSVGPath) return
        }
        const response = await doRequest('get', `api/v1/image/${data.image_id}?thumbnail=${thumbnail}`)
        const imageBlob = await response.blob()
        const imageUrl = URL.createObjectURL(imageBlob);
        if (thumbnail) {
            setTest(true)
            setThumbnailData(imageUrl);
        } else {
            setImageData(imageUrl)
        }
    }

    function useIsInViewport(ref: any, tbd: string) {
        const [isIntersecting, setIsIntersecting] = useState(false);

        const observer = useMemo(
            () =>
                new IntersectionObserver(([entry]) => {
                        if (entry.isIntersecting && !isIntersecting) {
                            getImage(true, tbd, imageData);
                        }
                        return setIsIntersecting(entry.isIntersecting)
                    },
                ),
            [tbd],
        );

        useEffect(() => {
            observer.observe(ref.current);

            return () => {
                observer.disconnect();
            };
        }, [ref, observer]);
        return isIntersecting;
    }

    const [ref, setRef] = useState();
    let height = 0
    let width = 0
    useIsInViewport(imgRef, thumbnailData)
    useEffect(() => {
        //@ts-ignore
        if (ref) {
            //@ts-ignore
            height = ref.clientHeight
            //@ts-ignore
            width = ref.clientWidth
        }
        //@ts-ignore
    }, [ref])

    return (<div className={"image-wrapper-class"} onClick={() => {
        getImage(false, thumbnailData, imageData)
        setModalOpen(true)
    }}>
        <ReactModal
            ariaHideApp={false}
            onRequestClose={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setModalOpen(false);
            }}
            isOpen={modalOpen}
            shouldCloseOnOverlayClick={true}
            parentSelector={() => document.body}
        >
            <div className={"model-content-class"}>
                {/*@ts-ignore */}
                <img ref={newRef => setRef(newRef)}
                     className={`${imageData === '/loading_small.svg' ? 'loading' : ''}`}
                     alt={`c${clusterIndex}i${index}`}
                     id={`c${clusterIndex}i${data.image_id}`}
                     src={imageData}>
                </img>
                {task !== 'classification' && clusterImageBoxes.every((c) => c) && clusterImageBoxes.map((cib: any) => {

                    return (<div key={cib} style={{
                        "boxSizing": "border-box",
                        "border": `4px solid ${clusterColor}`,
                        "position": "absolute",
                        "top": height * cib[1],
                        "left": width * cib[0],
                        "height": `${height * (cib[3] - cib[1])}px`,
                        "width": `${width * (cib[2] - cib[0])}px`
                    }}></div>)
                })}

            </div>
        </ReactModal>

        <img ref={imgRef} loading={"lazy"} onMouseEnter={() => {
            onHover(data.image_id)
        }} onMouseLeave={() => {
            onHover(null)
        }} id={`c${clusterIndex}i${data.image_id}`}
             className={`wrapper-image-class ${thumbnailData === '/loading_small.svg' ? 'loading' : ''}`}
             src={thumbnailData}/>
        {/* {showSeeMore ?
    <div className={"image-see-more-class info-on-image-class"} onClick={async (e) => {
        e.stopPropagation()
        setLoading(true)
        await loadSimilar(clusterIndex, data.image_id)
        setLoading(false)
      }}>
        {loading ? <img src={'/loading_button.svg'}/> : 'See More'}
      </div> : <></>
    } */}
        {data.score &&
            <div className={"image-score-wrapper-class info-on-image-class"}>
                {data.score.toFixed(2)}
            </div>}
    </div>)
}

export default ImageWrapper;
