import InputWithTitle from "../../../components/input_with_title";
import Box from "@mui/material/Box";
import React from "react";
import {setCredentialsAction} from "../../../actions/user";
import {connect} from "react-redux";


type props = {
    setCredentials: (name: string, value: string) => void;
}


const S3Modal = ({setCredentials}: props) => {
    return (
        <Box paddingTop={2}>
            <InputWithTitle
                placeholder={"Name"}
                onChange={setCredentials}
                title={"Name your integration"}
                name={"name"}
            ></InputWithTitle>
            <InputWithTitle
                placeholder={"AWS S3 access key"}
                onChange={setCredentials}
                title={"AWS S3 access key "}
                name={"access_key"}
            ></InputWithTitle>
            <InputWithTitle
                placeholder={"AWS S3 secret access key"}
                onChange={setCredentials}
                title={"AWS S3 secret access key "}
                name={"secret_access_key"}
            ></InputWithTitle>
            <InputWithTitle
                placeholder={"AWS S3 region name"}
                onChange={setCredentials}
                title={"AWS S3 region name"}
                name={"region"}
            ></InputWithTitle>
            <InputWithTitle
                placeholder={"AWS S3 bucket name"}
                onChange={setCredentials}
                title={"AWS S3 bucket name "}
                name={"bucket"}
            ></InputWithTitle>
        </Box>
    )
}

const mapStateToProps = (state: any) => ({
    newCredentials: state.newCredentials,
});

const mapDispatchToProps = (dispatch: any) => ({
    setCredentials: (name: string, value: string) =>
        dispatch(setCredentialsAction(name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S3Modal)