import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

type Props = {
    infoText?: string;
    title: string;
    children: JSX.Element;
};

const MiniCard = ({ infoText, title, children }: Props) => {
    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
        >
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                marginBottom={1}
            >
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                >
                    <Typography
                        fontSize={14}
                        fontWeight={"500"}
                        paddingRight={1}
                        color={"rgba(0, 0, 0, 0.87)"}
                    >
                        {title}
                    </Typography>
                    <Tooltip arrow title={infoText} placement="top">
                        <IconButton
                            disableFocusRipple
                            disableRipple
                            sx={{
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: "initial",
                                },
                            }}
                        >
                            <img
                                width={14}
                                height={14}
                                alt={"info icon"}
                                src={`/info.svg`}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            {children}
        </Box>
    );
};

export default MiniCard;
