import {doRequest} from "../helpers/request_helper";
import {snakeCaseKeys} from "../helpers/snake_case_keys";
import moment from 'moment';
import loading from "../icons/loading";

const setEvaluationDataAction = (key: string, value: any) => {
    return {
        type: 'SET_EVALUATION_DATA',
        payload: {
            key,
            value
        }
    }
}

const clearEvaluationInputPaths = () => {
    return {
        type: 'CLEAR_EVALUATION_INPUT_PATHS',
        payload: null
    }
}

const clearEvaluationData = () => {
    return {
        type: "RESET_NEW_EVALUATION",
        payload: null
    }
}

const startEvaluationAction = async (newEvaluation: any) => {

    let endpoint = 'api/v1/evaluation/';
    const response: Response = await doRequest('post', endpoint, snakeCaseKeys(newEvaluation),)
    const res = await response.json()
    if (response.status === 201) {
        window.location.href = `/projects/${newEvaluation.project_id}`
        return {
            type: 'EVALUATION_CREATED',
            payload: res
        }
    } else {
        let errors = []
        if (res.message) {
            errors.push({
                'type': 'error',
                'message': res.message
            })
        } else {
            errors = res.errors
        }
        return {
            type: "SET_ERRORS",
            payload: errors
        }
    }

}

const getEvaluationAction = async (evaluationId: number) => {
    const response: Response = await doRequest('get', `api/v1/evaluation/${evaluationId}`)
    if (response.status === 200) {
        const evaluation = await response.json()
        evaluation.clusters_data_set = evaluation.clusters_data_set
        return {
            type: 'GET_EVALUATION',
            payload: evaluation
        }
    } else if (response.status === 404) {
        window.location.href = '/not-found'
        return {
            type: 'SET_ERRORS',
            payload: {text: 'Not Found', status: 404}
        }

    }
}

const recluster = async (evaluationId: number, classBased: boolean, imageIds: number[], alpha: number) => {

    const response: Response = await doRequest('post', `api/v1/evaluation/${evaluationId}/recluster`, {
        alpha: alpha,
        class_based: classBased,
        image_indexes: imageIds.join(',')
    });
    if (response && response.status === 200) {
        const clusters = await response.json();
        return {
            type: 'GET_RECLUSTERS',
            payload: clusters
        };

    } else {
        return {
            type: 'GET_RECLUSTERS_FAILED',
            payload: []
        };

    }
}

const downloadCluster = async (imageIds: number[]) => {
    const response: Response = await doRequest('get', `api/v1/image/download?image_ids=${imageIds.join(',')}`)
    const blob = await response.blob()
    const link = document.createElement("a");
    const filename = moment().format('YYYY-MM-DD HH:mm:SS') + '.zip'
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    return {
        type: 'DOWNLOAD_IMAGES',
        payload: null
    }
}

const resetZoomAction = () => {
    return {
        type: 'RESET_ZOOM'
    }
}
const sendToLabelingAction = async (evaluation_id: number, integration_id: number) => {
    const response: Response = await doRequest('post', `api/v1/evaluation/${evaluation_id}/labeling`,
        {integration_id: integration_id})
    if (response && response.status === 200) {
        const res = await response.json();
        window.open(res['project_url'], '_blank')
        return {
            type: "Success"
        }
    } else {
        let errors = []
        const res = await response.json()
        if (res.message) {
            errors.push({
                'type': 'error',
                'message': res.message
            })
        } else {
            errors = res.errors
        }
        return {
            type: "SET_ERRORS",
            payload: errors
        }
    }
}

const filterCluster = async (boxIds: number[], text: string, evaluationId: number) => {
    const response: Response = await doRequest('post', `api/v1/evaluation/${evaluationId}/filter`, {
        box_indexes: boxIds.join(','),
        text: text
    })
    const clusters = await response.json()
    return {
        type: 'GET_RECLUSTERS',
        payload: clusters
    }
}

const loadSimilarAction = async (clusterIndex: number, imageId: number) => {
    const response: Response = await doRequest('get', `api/v1/image/${imageId}/similars`)
    if (response && response.status === 200) {
        const similarDataset = await response.json()
        return {
            type: 'GET_SIMILARS',
            payload: {clusterIndex, similarDataset}
        }

    } else {
        let errors = []
        const res = await response.json()
        if (res.message) {
            errors.push({
                'type': 'error',
                'message': res.message
            })
        } else {
            errors = res.errors
        }
        return {
            type: "SET_ERRORS",
            payload: errors
        }
    }
}

export {
    startEvaluationAction,
    getEvaluationAction,
    recluster,
    downloadCluster,
    filterCluster,
    resetZoomAction,
    loadSimilarAction,
    setEvaluationDataAction,
    sendToLabelingAction,
    clearEvaluationInputPaths,
    clearEvaluationData
}

