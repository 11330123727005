const setErrorAction = (error: any) => {
  return {
    type: "SET_ERROR",
    payload: error
  }

}
const removeErrorAction = (name: string) => {
  return {
    type: "REMOVE_ERROR",
    payload: name
  }
}
export { setErrorAction, removeErrorAction }