import React, {Fragment, useEffect, useRef, useState} from "react";
import "./styles.scss";
import {
    downloadCluster,
    filterCluster,
    getEvaluationAction,
    loadSimilarAction,
    recluster,
    sendToLabelingAction,
} from "../../actions/evaluation";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {Scatter} from "react-chartjs-2";
import {find, isEmpty, keys, orderBy, pickBy, uniqBy} from "lodash";
import {
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip as ChartTooltip,
} from "chart.js/auto";

import zoomPlugin from "chartjs-plugin-zoom";
import customCanvasBackgroundColor from "../../plugins/chartJsBackgroundPlugin";
import {ClusterImage} from "../../types/cluster_image";
import {setBreadcrumbs} from "../../actions/page";
import Input from "../../components/input";
import Button from "../../components/button";
import Loading from "../../components/loading";
import ImageWrapper from "../../components/image_wrapper";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Tooltip from "../../components/tooltip";
import {useNavigate} from "react-router";
import Card from "../../components/card";
import MiniCard from "../../components/mini_card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {styled} from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {alertTitleClasses} from "@mui/material";
import app from "../../App";

ChartJS.register(
    LinearScale,
    PointElement,
    LineElement,
    ChartTooltip,
    Legend,
    zoomPlugin
);

type EvaluationResponseModel = {
    evaluation: {
        id: number;
        name: string;
        number_of_images: number;
        progress: number;
        status: string;
        clusters_data_set: ClusterImage[];
        created_at: string;
        data_set_id: number;
        extracted_images: number;
        project: any;
        min_score: number;
        max_score: number;
        description: string | null;
        databricks_table_url: string | null;
    };
    getEvaluationAction: any;
    reclusterAction: (
        evaluationId: number,
        classBased: boolean,
        imageIds: number[],
        alpha: number
    ) => void;
    downloadClusterAction: (imageIds: number[]) => void;
    setBreadcrumbs: (breadcrumbs: any[]) => void;
    filterCluster: (
        boxIds: number[],
        text: string,
        evaluationId: number
    ) => void;
    loadSimilar: (clusterIndex: number, imageId: number) => void;
    similarDataset?: { clusterIndex: number };
    user: any;
    clusters: ClusterImage[];
    sendToLabeling: (evaluation_id: number, integration_id: number) => void;
};

function Evaluation({
                        evaluation,
                        getEvaluationAction,
                        reclusterAction,
                        setBreadcrumbs,
                        downloadClusterAction,
                        filterCluster,
                        loadSimilar,
                        sendToLabeling,
                        user,
                        clusters,
                        similarDataset,
                    }: EvaluationResponseModel) {
    let handle: undefined | NodeJS.Timeout = undefined;
    let prevElem: HTMLElement | null = null;
    const {evaluationId} = useParams();
    const [searchValue, setSearchValue] = useState("");
    const [classBased, setClassBased] = useState(true);
    const [sliderValue, setSliderValue] = useState(
        parseInt(localStorage.getItem(`${evaluationId}_slider_value`) || "1")
    );
    const [loading, setLoading] = useState(false);
    const containerRef = React.useRef<HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState<number | null>(null);
    const [score, setScore] = useState<number[]>([0, 0]);
    const [marks, setMarks] = useState<{ value: number; label: string }[]>([
        {
            value: 0,
            label: "0",
        },
        {
            value: 10,
            label: "10",
        },
    ]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const rand = (n: number) => {
        return Math.floor(Math.random() * n);
    };

    useEffect(() => {
        const getEvaluation = () => {
            getEvaluationAction(evaluationId);
        };
        getEvaluation();
    }, []);

    //@ts-ignore
    useEffect(() => {
        if (!isEmpty(evaluation)) {
            const crumbs = [
                {
                    name: "Projects",
                    path: "/",
                },
                {
                    name: evaluation?.project?.name,
                    path: `/projects/${evaluation?.project?.id}`,
                },
                {
                    name: evaluation.name,
                    path: `/evaluations/${evaluation.id}`,
                },
            ];
            setBreadcrumbs(crumbs);
        }
    }, [evaluation]);

    useEffect(() => {
        if (!isEmpty(evaluation)) {
            setScore([parseFloat(evaluation.min_score.toFixed(2)), parseFloat(evaluation.max_score.toFixed(2))]);
        }
    }, [evaluation?.id]);

    useEffect(() => {
        setMarks([
            {
                value: 0,
                label: "0",
            },
            {
                value: score[0],
                label: score[0].toString(),
            },
            {
                value: score[1],
                label: score[1].toString(),
            },
            {
                value: 10,
                label: "10",
            },
        ]);
    }, [score]);

    // let cI = JSON.stringify(clusters)
    const [datasetArr, setDatasetArr] = useState<any>([]);
    const [filterLoading, setFilterLoading] = useState(false);
    let clustersDataset = null;
    if (evaluation && clusters) {
        clustersDataset = JSON.stringify(clusters);
    }

    useEffect(() => {
        let ds = {};
        if (evaluation && clusters) {
            clusters.forEach((cluster) => {
                //@ts-ignore
                if (ds[cluster.cluster_id]) {
                    // @ts-ignore
                    ds[cluster.cluster_id]["data"].push({
                        x: parseFloat(cluster.x),
                        y: parseFloat(cluster.y),
                        image_id: cluster.image_id,
                        box: cluster.box,
                        score: cluster.score,
                    });
                } else {
                    let color = `rgba(${rand(255)}, ${rand(255)}, ${rand(
                        255
                    )}, 1)`;
                    let dataset = {
                        data: [
                            {
                                x: parseFloat(cluster.x),
                                y: parseFloat(cluster.y),
                                image_id: cluster.image_id,
                                box: cluster.box,
                                score: cluster.score,
                            },
                        ],
                        backgroundColor: color,
                        borderColor: color,
                        cluster_description: cluster.cluster_description,
                    };
                    //@ts-ignore
                    ds[cluster.cluster_id] = dataset;
                }
            });
            ds = orderBy(
                Object.values(ds),
                (c: any) => {
                    return c.meanImpactScore;
                },
                "desc"
            );

            //@ts-ignore
            setDatasetArr(ds);
            // setData({datasets: ds})
        }
    }, [clustersDataset]);

    useEffect(() => {
        if (evaluation && clusters && open) {
            let imageIds = evaluation.clusters_data_set.map(
                (clusterImage: ClusterImage) => {
                    if (
                        clusterImage.score >= score[0] &&
                        clusterImage.score <= score[1]
                    ) {
                        return clusterImage.image_index;
                    }
                }
            );
            imageIds = imageIds.filter(Number.isFinite);

            //@ts-ignore
            reclusterAction(evaluation.id, classBased, imageIds, sliderValue);

        }
    }, [score[0], score[1], classBased]);

    // const updateSliderValue = (sliderValue: number) => {
    //     setSliderValue(sliderValue);
    //     localStorage.setItem(
    //         `${evaluation.id}_slider_value`,
    //         sliderValue.toString()
    //     );
    // };

    if (!evaluation || !user) {
        return <Loading></Loading>;
    }
    const onSearch = async (event: any) => {
        setFilterLoading(true);
        await filterCluster([], searchValue, evaluation.id);
        setFilterLoading(false);
    };

    const customRadius = (context: any) => {
        if (context.raw.image_id === selectedImage) {
            return 20;
        } else {
            return 3;
        }
    };

    const options = {
        // responsive: true,
        maintainAspectRatio: false,
        scaleShowLabels: false,
        legend: {
            display: false,
        },
        onClick: (chart: any, elem: any) => {
            if (elem.length) {
                //@ts-ignore
                goToCluster(
                    elem[0].datasetIndex,
                    elem[0].element.$context.raw.image_id
                );
            }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any) {
                    return tooltipItem.yLabel;
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                display: false,
            },
            x: {
                beginAtZero: true,
                display: false,
            },
        },
        elements: {
            point: {
                radius: customRadius,
                display: true,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem: any) {
                        return tooltipItem.yLabel;
                    },
                },
            },
            customCanvasBackgroundColor: {
                color: "rgb(245,243,243)",
            },
            zoom: {
                pan: {
                    enabled: true,
                    // onPanComplete: (chart: any) => {
                    //     clearTimeout(handle);

                    //     handle = setTimeout(() => {
                    //         recluster(chart);
                    //     }, 200);
                    // },
                },
                zoom: {
                    drag: {
                        enabled: true,
                        modifierKey: "mouse",
                    },
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: false,
                    },
                    mode: "xy",
                    // onZoomComplete: (chart: any) => {
                    //     clearTimeout(handle);

                    //     handle = setTimeout(() => {
                    //         recluster(chart);
                    //     }, 200);
                    // },
                },
            },
        },
    };

    //@ts-ignore
    if (!evaluation) {
        return null;
    }

    const recluster = async (chart: any) => {
        const scales = chart.chart._sortedMetasets[0]._scaleRanges;
        if (evaluation && clusters) {
            const indexes = keys(
                pickBy(clusters, (cd) => {
                    //@ts-ignore
                    return (
                        cd.x >= scales.xmin &&
                        cd.x <= scales.xmax &&
                        cd.y >= scales.ymin &&
                        cd.y <= scales.ymax
                    );
                })
            ).map((i: string) => parseInt(i));

            await reclusterAction(
                evaluation.id,
                classBased,
                indexes,
                sliderValue
            );
        }
    };
    if (isEmpty(evaluation)) {
        return null;
    }

    const goToCluster = (datasetIndex: number, imageId: number) => {
        const elem = document.getElementById(`c${datasetIndex}i${imageId}`);
        if (prevElem) {
            prevElem.style.borderColor = "";
            prevElem.classList.remove("image-selected");
        }
        if (elem) {
            prevElem = elem;
            elem.scrollIntoView();
            //@ts-ignore
            elem.style.borderColor = datasetArr[datasetIndex].backgroundColor;
            elem.classList.add("image-selected");
        }
    };
    datasetArr.map((cluster: any, clusterIndex: any) => {
        const meanImpactScore = (
            cluster.data.reduce((d: number, cluster_data: object) => {
                // @ts-ignore
                return d + cluster_data.score;
            }, 0) / cluster.data.length
        ).toFixed(2);
        cluster.meanImpactScore = meanImpactScore;
    });

    const minScoreDistance = parseFloat(
        ((evaluation.max_score - evaluation.min_score) / 10).toFixed(2)
    );

    function valuetext(value: number) {
        return `${value}°C`;
    }

    const handleScoreChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (
            newValue[0] >= score[1] ||
            newValue[1] <= score[0] ||
            // eslint-disable-next-line no-mixed-operators
            (score[0] === score[1] - minScoreDistance &&
                newValue[0] > score[0]) ||
            // eslint-disable-next-line no-mixed-operators
            (score[1] === score[0] + minScoreDistance && newValue[1] < score[1])
        ) {
            return;
        }
        if (activeThumb === 0) {
            setScore([
                Math.min(newValue[0], score[1] - minScoreDistance),
                score[1],
            ]);
        } else {
            setScore([
                score[0],
                Math.max(newValue[1], score[0] + minScoreDistance),
            ]);
        }
    };

    const toggleSlide = () => {
        setOpen(!open);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const Modal = ({isOpen, onClose}: any) => {

        return (
            <div style={{
                display: isOpen ? 'block' : 'none',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#fff',
                    padding: '20px',
                    borderRadius: '8px'
                }}>
                    <h2>Select an option</h2>
                    <div style={{display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column'}}>
                        <button style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            padding: '5px',
                            border: 'none',
                            backgroundColor: 'transparent',
                            cursor: 'pointer'
                        }} onClick={onClose}>X
                        </button>
                        {user.credentials.map((integration: any) => (
                            integration.type === 'label_studio' &&
                            (<Button text={integration.name}
                                     onClick={async () => {
                                         setLoading(true)
                                         await sendToLabeling(evaluation.id, integration.id)
                                         closeModal()
                                         setLoading(false)
                                     }}/>)
                        ))}
                    </div>
                </div>
            </div>
        );
    };


    return (
        <>
            {loading && <Loading></Loading>}
            <Box>
                <Card
                    title={"Insights"}
                    infoText="View insights below. Send to your labeling provider, download an image or cluster of images, or share this evaluation on the project page."
                    buttonText={"Send to Labeling"}
                    buttonIcon={
                        <img
                            width={14}
                            height={14}
                            alt="label icon"
                            src={"/label.svg"}
                        />
                    }
                    buttonAction={async () => {
                        openModal()
                    }}
                >
                    <>
                        <Modal isOpen={isModalOpen} onClose={closeModal}/>
                        {selectedOption && <p>Selected option: {selectedOption}</p>}
                        <Box>
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                            >
                                <Box
                                    display={"flex"}
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                    gap={0.25}
                                    flexWrap={"wrap"}
                                    marginBottom={8}
                                    marginLeft={"11px"}
                                >
                                    <Box
                                        flex={1}
                                        display="flex"
                                        justifyContent="flex-start"
                                    >
                                        <FormControlLabel
                                            sx={{
                                                marginRight: 0,
                                            }}
                                            control={
                                                <Button
                                                    text={"Filters"}
                                                    onClick={toggleSlide}
                                                    outline
                                                    toggle={open ? true : false}
                                                    icon={
                                                        <img
                                                            width={14}
                                                            height={14}
                                                            alt="filter icon"
                                                            src={
                                                                "/filter_color.svg"
                                                            }
                                                        />
                                                    }
                                                ></Button>
                                            }
                                            label=""
                                        />
                                    </Box>
                                    <Box
                                        flex={1}
                                        minWidth={200}
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <Box width={200}>
                                            <div style={{
                                                marginBottom: '5px',
                                                display: 'flex',
                                                flexDirection: 'row-reverse',
                                            }}>
                                                {evaluation.databricks_table_url &&
                                                    <Button text={'Open in databricks'} onClick={() => {
                                                        window.open(evaluation.databricks_table_url ? evaluation.databricks_table_url : '')
                                                    }} icon={
                                                        <img
                                                            width={14}
                                                            height={14}
                                                            alt="filter icon"
                                                            src={
                                                                "/databricks-icon.svg"
                                                            }
                                                        />
                                                    }/>}
                                            </div>
                                            <Input
                                                name={"search"}
                                                placeholder={"Search"}
                                                type={"search"}
                                                size={"medium"}
                                                onChange={(name, value) => {
                                                    setSearchValue(value);
                                                }}
                                                onBlur={onSearch}
                                                loading={filterLoading}
                                            ></Input>

                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        ref={containerRef}
                                        sx={{
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Slide
                                            direction="right"
                                            in={open}
                                            container={containerRef.current}
                                            mountOnEnter
                                            unmountOnExit
                                        >
                                            <Box>
                                                <Box
                                                    display={"flex"}
                                                    flexDirection={"column"}
                                                    justifyContent={"space-between"}
                                                    marginBottom={8}
                                                    paddingRight={4}
                                                    marginRight={4}
                                                    borderRight={
                                                        "1px solid #D9D9DB"
                                                    }
                                                    height={"100%"}
                                                    minWidth={"400px"}
                                                >
                                                    <Box>
                                                        <MiniCard
                                                            title="Impact Score Range"
                                                            infoText="Manot's `Impact Score` is an indicator of how well the model will perform on a given image. The higher the score, the poorer the model will perform."
                                                        >
                                                            <Box
                                                                maxWidth={"80%"}
                                                                marginLeft={1}
                                                            >
                                                                <Slider
                                                                    getAriaLabel={() =>
                                                                        "Minimum distance"
                                                                    }
                                                                    value={score}
                                                                    min={0}
                                                                    max={10}
                                                                    color={
                                                                        "secondary"
                                                                    }
                                                                    size={"small"}
                                                                    marks={marks}
                                                                    step={
                                                                        minScoreDistance
                                                                    }
                                                                    onChange={
                                                                        handleScoreChange
                                                                    }
                                                                    getAriaValueText={
                                                                        valuetext
                                                                    }
                                                                    disableSwap
                                                                    sx={{
                                                                        color: "#CA95B3",
                                                                        "& .MuiSlider-thumb":
                                                                            {
                                                                                color: "#CA95B3",
                                                                            },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </MiniCard>

                                                        <Box marginY={4}>
                                                            <MiniCard
                                                                title="Group By"
                                                                infoText="Explore patterns in the following TSNE visualizations."
                                                            >
                                                                <Input
                                                                    type={"toggle"}
                                                                    name={
                                                                        "class_based"
                                                                    }
                                                                    onChange={(
                                                                        k,
                                                                        value
                                                                    ) => {
                                                                        setClassBased(
                                                                            value
                                                                        );
                                                                    }}
                                                                    placeholder={
                                                                        "Toggle"
                                                                    }
                                                                    value={
                                                                        classBased
                                                                    }
                                                                    options={[
                                                                        {
                                                                            value: true,
                                                                            label: "Project Classes",
                                                                        },
                                                                        {
                                                                            value: false,
                                                                            label: "Identified Classes",
                                                                        },
                                                                    ]}
                                                                />
                                                            </MiniCard>
                                                        </Box>
                                                        <Box height={"400px"}>
                                                            <Scatter
                                                                /*@ts-ignore */
                                                                data={{
                                                                    datasets:
                                                                    datasetArr,
                                                                }}
                                                                /*@ts-ignore */
                                                                options={options}
                                                                plugins={[
                                                                    customCanvasBackgroundColor,
                                                                ]}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Slide>
                                        <Box
                                            sx={{
                                                overflowX: "hidden",
                                                overflowY: "auto",
                                                height: "calc(100vh - 15.625rem)",
                                                paddingRight: 4,
                                            }}
                                        >
                                            {datasetArr.map(
                                                (
                                                    cluster: any,
                                                    clusterIndex: number
                                                ) => {
                                                    return (
                                                        <Fragment
                                                            key={clusterIndex}
                                                        >
                                                            <Box
                                                                marginBottom={4}
                                                                sx={{
                                                                    overflowX:
                                                                        "hidden",
                                                                    overflowY:
                                                                        "hidden",
                                                                    minHeight:
                                                                        "12.25rem",
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                }}
                                                                key={clusterIndex}
                                                            >
                                                                <Box
                                                                    display={"flex"}
                                                                    flexDirection={
                                                                        "row"
                                                                    }
                                                                    justifyContent={
                                                                        "space-between"
                                                                    }
                                                                    alignContent={
                                                                        "center"
                                                                    }
                                                                    marginBottom={2}
                                                                >
                                                                    <Box
                                                                        display={
                                                                            "flex"
                                                                        }
                                                                        flexDirection={
                                                                            "column"
                                                                        }
                                                                        justifyContent={
                                                                            "flex-start"
                                                                        }
                                                                        paddingRight={
                                                                            4
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            fontSize={
                                                                                14
                                                                            }
                                                                            fontWeight={
                                                                                "500"
                                                                            }
                                                                            color={
                                                                                "rgba(0, 0, 0, 0.87)"
                                                                            }
                                                                        >
                                                                            {cluster.cluster_description
                                                                                .split(
                                                                                    ","
                                                                                )
                                                                                .join(
                                                                                    " "
                                                                                )}
                                                                        </Typography>
                                                                        <Typography
                                                                            fontSize={
                                                                                12
                                                                            }
                                                                            fontWeight={
                                                                                "500"
                                                                            }
                                                                            paddingTop={
                                                                                1
                                                                            }
                                                                            color={
                                                                                "rgba(0, 0, 0, 0.65)"
                                                                            }
                                                                        >
                                                                            {"(Mean Impact Score: " +
                                                                                cluster.meanImpactScore +
                                                                                ")"}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box
                                                                        marginRight={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Button
                                                                            text={
                                                                                "Download Cluster"
                                                                            }
                                                                            onClick={async () => {
                                                                                //@ts-ignore
                                                                                await downloadClusterAction(
                                                                                    datasetArr[
                                                                                        clusterIndex
                                                                                        ].data.map(
                                                                                        (c: {
                                                                                            image_id: any;
                                                                                        }) =>
                                                                                            c.image_id
                                                                                    )
                                                                                );
                                                                            }}
                                                                            outline
                                                                            icon={
                                                                                <img
                                                                                    width={
                                                                                        14
                                                                                    }
                                                                                    height={
                                                                                        14
                                                                                    }
                                                                                    alt="download icon"
                                                                                    src={
                                                                                        "/download.svg"
                                                                                    }
                                                                                />
                                                                            }
                                                                        ></Button>
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    className={
                                                                        "cluster-wrapper-class"
                                                                    }
                                                                >
                                                                    <Box
                                                                        className={
                                                                            "cluster-color-class"
                                                                        }
                                                                        style={{
                                                                            backgroundColor: `${cluster.backgroundColor}`,
                                                                        }}
                                                                    ></Box>
                                                                    <Box
                                                                        className={
                                                                            datasetArr.length >
                                                                            1
                                                                                ? "cluster-images-class"
                                                                                : "cluster-filtered-images-class"
                                                                        }
                                                                    >
                                                                        {uniqBy(
                                                                            cluster.data,
                                                                            (
                                                                                d: any
                                                                            ) => {
                                                                                return d.image_id;
                                                                            }
                                                                        ).map(
                                                                            (
                                                                                data: any,
                                                                                index: number
                                                                            ) => {
                                                                                const clusterImageBoxes =
                                                                                    cluster.data
                                                                                        .filter(
                                                                                            (
                                                                                                cd: any
                                                                                            ) => {
                                                                                                return (
                                                                                                    cd.image_id ==
                                                                                                    data.image_id
                                                                                                );
                                                                                            }
                                                                                        )
                                                                                        .map(
                                                                                            (
                                                                                                cd: any,
                                                                                                index: any
                                                                                            ) =>
                                                                                                cd.box
                                                                                        );
                                                                                return (
                                                                                    <ImageWrapper
                                                                                        onHover={
                                                                                            setSelectedImage
                                                                                        }
                                                                                        key={`${clusterIndex}_${data.image_id}`}
                                                                                        task={
                                                                                            evaluation
                                                                                                .project
                                                                                                .task
                                                                                        }
                                                                                        clusterImageBoxes={
                                                                                            clusterImageBoxes
                                                                                        }
                                                                                        clusterColor={
                                                                                            cluster.backgroundColor
                                                                                        }
                                                                                        clusterIndex={
                                                                                            clusterIndex
                                                                                        }
                                                                                        index={
                                                                                            index
                                                                                        }
                                                                                        data={
                                                                                            data
                                                                                        }
                                                                                        showSeeMore={
                                                                                            datasetArr.length >
                                                                                            1
                                                                                        }
                                                                                        loadSimilar={
                                                                                            loadSimilar
                                                                                        }
                                                                                    ></ImageWrapper>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            {/*@ts-ignore*/}
                                                            {similarDataset &&
                                                                similarDataset.clusterIndex ==
                                                                clusterIndex && (
                                                                    <Box
                                                                        className={
                                                                            "images-container-class"
                                                                        }
                                                                    >
                                                                        <p>
                                                                            {" "}
                                                                            Similar
                                                                            Images
                                                                        </p>
                                                                        <Box
                                                                            className={
                                                                                "cluster-wrapper-class"
                                                                            }
                                                                        >
                                                                            <Box
                                                                                className={
                                                                                    "cluster-images-class"
                                                                                }
                                                                            >
                                                                                {/*@ts-ignore*/}
                                                                                {similarDataset.similarDataset.map(
                                                                                    (
                                                                                        similarImage: {
                                                                                            id: any;
                                                                                        },
                                                                                        similarImageIndex: number
                                                                                    ) => {
                                                                                        return (
                                                                                            <ImageWrapper
                                                                                                onHover={
                                                                                                    setSelectedImage
                                                                                                }
                                                                                                key={`${clusterIndex}_${similarImage.id}`}
                                                                                                task={
                                                                                                    evaluation
                                                                                                        .project
                                                                                                        .task
                                                                                                }
                                                                                                clusterImageBoxes={[]}
                                                                                                clusterColor={
                                                                                                    cluster.backgroundColor
                                                                                                }
                                                                                                clusterIndex={
                                                                                                    clusterIndex
                                                                                                }
                                                                                                index={
                                                                                                    similarImageIndex
                                                                                                }
                                                                                                data={{
                                                                                                    image_id:
                                                                                                    similarImage.id,
                                                                                                }}
                                                                                                showSeeMore={
                                                                                                    false
                                                                                                }
                                                                                                loadSimilar={
                                                                                                    loadSimilar
                                                                                                }
                                                                                            ></ImageWrapper>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Box>
                                                                        </Box>
                                                                        {/*@ts-ignore*/}
                                                                    </Box>
                                                                )}
                                                        </Fragment>
                                                    );
                                                }
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            {/* </Main> */}
                        </Box>
                    </>
                </Card>
            </Box>
        </>
    );
}

const mapStateToProps = (state: any) => ({
    evaluation: state.evaluation,
    clusters: state.clusters,
    similarDataset: state.similarDataset,
    user: state.user,
});

const mapDispatchToProps = (dispatch: any) => ({
    getEvaluationAction: async (evaluationId: number) =>
        dispatch(await getEvaluationAction(evaluationId)),
    reclusterAction: async (
        evaluationId: number,
        classBased: boolean,
        imageIds: number[],
        alpha: number
    ) => dispatch(await recluster(evaluationId, classBased, imageIds, alpha)),
    downloadClusterAction: async (imageIds: number[]) =>
        dispatch(await downloadCluster(imageIds)),
    filterCluster: async (
        boxIds: number[],
        text: string,
        evaluationId: number
    ) => dispatch(await filterCluster(boxIds, text, evaluationId)),
    setBreadcrumbs: (breadcrumbs: any[]) => {
        dispatch(setBreadcrumbs(breadcrumbs));
    },
    loadSimilar: async (clusterIndex: number, imageId: number) =>
        dispatch(await loadSimilarAction(clusterIndex, imageId)),
    sendToLabeling: async (evaluation_id: number, integration_id: number) =>
        dispatch(await sendToLabelingAction(evaluation_id, integration_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Evaluation);
