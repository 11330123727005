import React, { useState } from 'react';
import './styles.scss';
import Button from "../../components/button";
import Input from "../../components/input";
import { forgetPasswordAction } from "../../actions/auth";
import { connect } from "react-redux";
import AuthErrors from "../../components/auth_errors";

type Props = {
  errors: [];
  forgetPassword: (email: string) => void;
  sent: boolean;
}

function ForgotPassword({ errors, forgetPassword, sent }: Props) {
  const [email, setEmail] = useState('');
  const handleSubmit = async (event: any) => {
    await forgetPassword(email)
  };
  return (<div className={'auth-container-class'}>
      {
        !sent && (
          <>
            <p className={'page-header-class'}>Forgot Password</p>
            <Input placeholder={'Email'} name={'email'} onChange={(key: string, value) => {
              setEmail(value)
            }}/>
            <Button text={'Recover Password'} onClick={handleSubmit}/>
          </>)
      }
      {sent && (<p className={'page-header-class'}>Password Sent, Please Check Your Email</p>)}
      <AuthErrors></AuthErrors>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  errors: state.errors,
  sent: state.forgetPasswordSent
});

const mapDispatchToProps = (dispatch: any) => ({
  forgetPassword: async (email: string) => {
    dispatch(await forgetPasswordAction(email))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);