import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
    createProject,
    createRoboflowProjectAction,
    setIgnoreLabelIdAction,
    setProjectDataAction,
} from "../../actions/project";
import { connect } from "react-redux";
import InputWithTitle from "../../components/input_with_title";
import InputWithImage from "../../components/input_with_image";
import Button from "../../components/button";
import { setBreadcrumbs } from "../../actions/page";
import NewProject from "../../types/new_project";
import { useNavigate } from "react-router";
import AuthErrors from "../../components/auth_errors";

type Props = {
    projectName: string;
    newProject: NewProject;
    setBreadcrumbs: (breadcrumbs: any[]) => void;
    createRoboflowProject: (newProject: any, navigate: any) => void;
    setProjectData: (name: string, value: any) => void;
    setIgnoreLabelId: (name: string, value: any) => void;
    errors: [];
};

function RoboflowImport({
    newProject,
    setProjectData,
    createRoboflowProject,
    setBreadcrumbs,
    errors,
}: Props) {
    useEffect(() => {
        setProjectData("dataProvider", "roboflow");
    }, [setProjectData]);
    const [ref, setRef] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const crumbs = [
        {
            name: "Projects",
            path: "/",
        },
        {
            name: "Add Project",
            path: "/add-project",
        },
        {
            name: "Roboflow Project",
            path: "/add-project/roboflow",
        },
    ];
    const toggleOptions = [
        {
            label: "Detection",
            value: "detection",
        },
        {
            label: "Segmentation",
            value: "segmentation",
        },
        {
            label: "Classification",
            value: "classification",
        },
    ];
    const metadataOptions = [
        { label: "cxcywh", value: "cxcywh" },
        { label: "xywh", value: "xywh" },
        {
            label: "xyx2y2",
            value: "xyx2y2",
        },
    ];
    const labelsOptions = [
        {
            label: ".xml",
            options: [{ label: "PascalVOC", value: "PascalVOC" }],
        },
        { label: ".txt", options: [{ label: "YOLO", value: "YOLO" }] },
        { label: ".json", options: [{ label: "COCO", value: "COCO" }] },
    ];

    useEffect(() => {
        setBreadcrumbs(crumbs);
    });
    const [ignoreLabel, setIgnoreLabel] = useState(false);

    return (
        <div className={"add-project-class"}>
            <div>
                <div className={"project-info-class"}>
                    <InputWithTitle
                        title={"Project Name"}
                        size={"long"}
                        placeholder={"Project Name"}
                        name={"name"}
                        validation={"^.{1,}$"}
                        onChange={setProjectData}
                    ></InputWithTitle>
                    <InputWithTitle
                        title={"Project Description"}
                        size={"long"}
                        placeholder={"Project Description"}
                        name={"description"}
                        validation={"^.{1,1000}$"}
                        onChange={setProjectData}
                    ></InputWithTitle>

                    <p className={"delimiter-classname"}> </p>
                    <div className={"input-group-class"}>
                        <p className={"input-group-title-class"}>
                            Manual Input
                        </p>
                        <InputWithImage
                            subtitle={"ID of your workspace"}
                            size={"long"}
                            type={"text"}
                            title={"Roboflow workspace ID"}
                            validation={"^.{1,}$"}
                            name={"roboflow_workspace_id"}
                            placeholder={"Roboflow workspace ID"}
                            imagePath={"/image.svg"}
                            onChange={setProjectData}
                        ></InputWithImage>

                        <InputWithImage
                            subtitle={"ID of your project"}
                            size={"long"}
                            type={"text"}
                            title={"Roboflow project ID"}
                            validation={"^.{1,}$"}
                            name={"roboflow_project_id"}
                            placeholder={"Roboflow project ID"}
                            imagePath={"/image.svg"}
                            onChange={setProjectData}
                        ></InputWithImage>

                        <InputWithImage
                            subtitle={"Version of roboflow project"}
                            size={"long"}
                            type={"text"}
                            title={"Roboflow project version"}
                            validation={"^.{1,}$"}
                            name={"roboflow_project_version"}
                            placeholder={"Roboflow project version"}
                            imagePath={"/image.svg"}
                            onChange={setProjectData}
                        ></InputWithImage>
                    </div>
                    <div className={"button-wrapper-class"}>
                        <Button
                            loading={loading}
                            text={"Back"}
                            onClick={() => {
                                navigate("/add-project");
                            }}
                        ></Button>
                        <Button
                            text={"Create Project"}
                            onClick={async () => {
                                const validationErrors = errors.filter(
                                    (e: { type: string }) =>
                                        e.type == "validationError"
                                );
                                if (validationErrors.length > 0) {
                                    return;
                                }
                                await createRoboflowProject(
                                    newProject,
                                    navigate
                                );
                            }}
                        ></Button>
                    </div>
                </div>
            </div>
            <AuthErrors></AuthErrors>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    projectName: state.newProject.projectName,
    newProject: state.newProject,
    errors: state.errors,
});

const mapDispatchToProps = (dispatch: any) => ({
    setProjectData: (key: string, value: any) =>
        dispatch(setProjectDataAction(key, value)),
    createRoboflowProject: async (newProject: any, navigate: any) =>
        dispatch(await createRoboflowProjectAction(newProject, navigate)),
    setIgnoreLabelId: async (key: string, value: string) =>
        dispatch(setIgnoreLabelIdAction(key, value)),
    setBreadcrumbs: (breadcrumbs: any[]) => {
        dispatch(setBreadcrumbs(breadcrumbs));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RoboflowImport);
