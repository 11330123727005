import {validationSchema} from "../../../types/evaluation";

export const s3_validation: validationSchema[] = [
    {
        fieldName: 'name',
        title: 'Name',
        validationRule: '^.{1,}$',
        errorMessage: 'Project name is required'
    },
    {
        fieldName: 'dataPath',
        title: 'Data Path',
        validationRule: '^.{1,}$', // Validation rule for Databricks Workspace URL
        errorMessage: 'Data Path is required.'
    },
]