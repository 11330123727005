import React from 'react';
import './styles.scss';
import { setGoogleCredentials } from "../../actions/auth";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";

function GoogleSignIn() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()
  //@ts-ignore
  setGoogleCredentials(searchParams.get('token'))
  navigate('/')
  return null
}

export default GoogleSignIn


