import React from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { every } from 'lodash';
import { authHeaderKeys } from './actions/auth';

const AuthenticatedRoute = () => {
  const [searchParams,setSearchParams] = useSearchParams()
  function isAuthenticated() {
    const isAuthenticated = every(authHeaderKeys, (key: string) => {
      return localStorage.getItem(key) && localStorage.getItem(key) !== 'null'
    })

    searchParams.get('project_token')
    return isAuthenticated || !!searchParams.get('project_token');
  }


  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isAuthenticated() ? <Outlet/> : <Navigate to="/sign-in"/>;
}
export default AuthenticatedRoute