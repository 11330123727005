import React, { useEffect, useRef } from "react";
import Project from "../../types/project";
import Matrix from "../../helpers/matrix_helper";

export type Props = {
    project: Project;
};

function ConfusionMatrix({ project }: Props) {
    const ref = useRef(null);

    useEffect(() => {
        Matrix({
            container: ".confusion-matrix-class",
            data: project.metrics?.confusion_matrix.map((row: number[]) => {
                return row.map((col: number | string) => {
                    if (typeof col === "number") {
                        return parseFloat(col.toFixed(2));
                    } else if (typeof col === "string") {
                        return col;
                    } else if (col === null){
                        return "null"
                    }
                });
            }),
            //@ts-ignore
            labels: Object.values(project.execution_args.classes),
            start_color: "#ffffff",
            middle_color: "rgb(192, 33, 123)",
            end_color: "rgb(89, 18, 95)",
        });
    }, [ref]);

    return (
        <div className={"confusion-matrix-wrapper"}>
            <div className={"actual-values-class"}>Predicted Values</div>
            <div className={"predicted-matrix-class"}>
                <div className={"predicted-values-class"}>Actual Values</div>
                <div className={"confusion-matrix-class"}></div>
                <div className={"gradient-class"}></div>
            </div>
        </div>
    );
}

export default ConfusionMatrix;
