import React from 'react';
import './styles.scss';
import Input from '../input'
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import QuestionIcon from '@mui/icons-material/QuestionMark';

export type Props = {
    placeholder: string;
    onChange: (key: string, value: any) => void;
    size?: string;
    type?: string;
    options?: { value: string, label: string }[];
    onKeyPress?: any;
    title: string;
    value?: any
    subtitle?: string;
    imagePath: string;
    name: string;
    validation?: string;
    tooltipText?: string
};

function InputWithImage({
                            placeholder,
                            subtitle,
                            onChange,
                            type = 'text',
                            size = 'large',
                            options,
                            onKeyPress,
                            title,
                            value,
                            imagePath,
                            name,
                            validation,
                            tooltipText
                        }: Props) {
    return (<div style={{'display': 'block'}}>

        <h3 className={'input-with-image-title-class'}>{title} {subtitle &&
            <h4 className={'input-with-image-subtitle-class'}>{subtitle}</h4>}
            {tooltipText &&
                // <Tooltip title={tooltipText}>
                //     <IconButton>
                //         <QuestionIcon/>
                //     </IconButton>
                // </Tooltip>
                <Tooltip arrow title={tooltipText} placement="top">
                    <IconButton
                        disableFocusRipple
                        disableRipple
                        sx={{
                            padding: 0,
                            "&:hover": {
                                backgroundColor: "initial",
                            },
                        }}
                    >
                        <img
                            width={16}
                            height={16}
                            alt={"info icon"}
                            src={`/info.svg`}
                        />
                    </IconButton>
                </Tooltip>
            }
        </h3>
        <div className={'input-with-image-group-class'}>
            <img src={imagePath}/>
            <Input placeholder={placeholder} validation={validation} onChange={onChange} type={type} size={size}
                   options={options}
                   onKeyPress={onKeyPress} value={value} name={name}></Input>
        </div>
    </div>);
}

export default InputWithImage;
