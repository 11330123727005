import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      info: {
        optimize: 'Feedback Loop Reduction',
        model: 'Model Performance Monitoring',
        insights: 'Data Insights and Proposals'
      },
      yolov5s: 'YOLOv5s',
      trash: 'YOLOv5s',
      box: 'Bounding Box',
      left: {
        title: "Without manot",
        description: "Below you can find a list of state-of-the-art computer vision models that can detect objects, do classification and other tasks. Pick one from the list below and try it yourself.",
        yolov5s: {
          title: 'Human Detection Model',
          description: 'Samples of inference results of the model.',
        },
        trash: {
          title: 'Trash Detection Model',
          description: 'Detection model for detecting trash in the frames of the images. ',
        },
        footerText: 'Try your own!',
        footerDescription: 'Upload your dataset to test the model...',
        uploadImages: 'Upload Images',

      },
      right: {
        title: "With manot",
        description: "Did your computer vision model just fail and you have no idea why? Gain insights using manot on where your model is performing poorly, and what data samples you should add to your training set to improve the performance of your model.",
        yolov5s: {
          title: "manot's insights",
          description: 'Real-world insights on where the model is likely to fail.',
        },
        trash: {
          title: "manot's insights",
          description: 'Real-world insights on where the model is likely to fail.',
        },
        anomalyText: 'Anomalies detected...',
        downloadImages: 'Download',
        moreLikeThis: 'More Like this',
        showInsights: {
          fromGlobalDataset: 'Get Insights',
          fromUploadedDataset: 'Upload Your Dataset',
        }
      },
    },

  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: false,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;




