import { doRequest } from "../helpers/request_helper";

const getProjectsAction = async () => {
  const response: Response = await doRequest('get', `api/v1/project/`)

  const projects = await response.json()

  return {
    type: "GET_PROJECTS",
    payload: projects
  }
}
const retryProjectAction = async (projectId: number) => {
  const response: Response = await doRequest('post', `api/v1/project/${projectId}/retry`)
  if (response.status === 200) {
    window.location.reload()
    return {
      type: "RETRY_PROJECT",
      payload: null
    }
  } else {
    const res = await response.json();
    let errors = [];
    if (res.message) {
      errors.push({
        'type': 'error',
        'message': res.message
      })
    } else {
      errors = res.errors
    }

    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: errors
    }
  }
}
export { getProjectsAction, retryProjectAction }