import { doRequest } from "../helpers/request_helper";
import { snakeCaseKeys } from "../helpers/snake_case_keys";
import { cloneDeep, orderBy } from "lodash";
import { removeNotifications } from "./page";

const setDataProvider = (dataProvider: string) => {
  return {
    type: 'SET_DATA_PROVIDER',
    payload: dataProvider,
  };
}
const clearProjectInputPaths = () => {
  return {
    type: 'CLEAR_PROJECT_INPUT_PATHS',
    payload: null
  }
}

const setProjectName = (projectName: string) => {
  return {
    type: 'SET_PROJECT_NAME',
    payload: projectName,
  };
}
const setTask = (setTask: string) => {
  return {
    type: 'SET_TASK',
    payload: setTask,
  };
}
const setNewProjectDataAction = (key: string, value: any) => {
  return {
    type: 'SET_NEW_PROJECT_DATA_ACTION',
    payload: { [key]: value }
  };
}

const setImagesPath = (imagesPath: string) => {
  return {
    type: 'SET_IMAGES_PATH',
    payload: imagesPath,
  };
}
const setGroundTruthsPath = (groundTruthsPath: string) => {
  return {
    type: 'SET_GROUND_TRUTHS_PATH',
    payload: groundTruthsPath,
  };
}
const setDetectionsPath = (detectionsPath: string) => {
  return {
    type: 'SET_DETECTIONS_PATH',
    payload: detectionsPath,
  }
}
const setDetectionsMetadataFormat = (detectionsMetadataFormat: string) => {
  return {
    type: 'SET_DETECTIONS_METADATA_FORMAT',
    payload: detectionsMetadataFormat,
  }
}
const setClassesPath = (classesPath: string) => {
  return {
    type: 'SET_CLASSES_PATH',
    payload: classesPath,
  }
}

const createProject = async (newProject: any, navigate: any) => {
  let url = 'api/v1/project/';
  const sendingProject = cloneDeep(newProject);
  sendingProject.classes = JSON.stringify(sendingProject.classes);
  const response: Response = await doRequest('post', url, snakeCaseKeys(sendingProject));
  if (!response) {
    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: ['something went wrong']
    }
  }
  if (response.status === 201) {
    const setup = await response.json();
    navigate('/')
    return {
      type: 'PROJECT_CREATED',
      payload: setup
    }

  } else {
    const res = await response.json();
    let errors = [];
    if (res.message) {
      errors.push({
        'type': 'error',
        'message': res.message
      })
    } else {
      errors = res.errors;
    }
    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: errors
    }
  }
}
const createRoboflowProjectAction = async (newProject: any, navigate: any) => {
  let url = 'api/v1/project/roboflow';
  const sendingProject = cloneDeep(newProject);
  const response: Response = await doRequest('post', url, snakeCaseKeys(sendingProject));
  if (!response) {
    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: ['something went wrong']
    }
  }
  if (response.status === 201) {
    const setup = await response.json();
    navigate('/projects')
    return {
      type: 'PROJECT_CREATED',
      payload: setup
    }

  } else {
    const res = await response.json();
    let errors = [];
    if (res.message) {
      errors.push({
        'type': 'error',
        'message': res.message
      })
    } else {
      errors = res.errors;
    }
    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: errors
    }
  }
}
const createDatabricksProjectAction = async (newProject: any, navigate: any) => {
  let url = 'api/v1/project/databricks';
  const sendingProject = cloneDeep(newProject);
  sendingProject.classes = JSON.stringify(sendingProject.classes);
  const response: Response = await doRequest('post', url, snakeCaseKeys(sendingProject));
  if (!response) {
    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: ['something went wrong']
    }
  }
  if (response.status === 201) {
    const setup = await response.json();
    navigate('/projects')
    return {
      type: 'PROJECT_CREATED',
      payload: setup
    }

  } else {
    const res = await response.json();
    let errors = [];
    if (res.message) {
      errors.push({
        'type': 'error',
        'message': res.message
      })
    } else {
      errors = res.errors;
    }
    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: errors
    }
  }
}
const getProjectAction = async (projectId: string, projectToken: string | null = null) => {
  const response: Response = await doRequest('get', `api/v1/project/${projectId}?project_token=${projectToken ? projectToken : ''}`)
  if (response.status === 200) {
    let project = await response.json()
    project.evaluations = orderBy(project.evaluations, (evaluation: { id: number }) => evaluation.id, 'desc')
    return {
      type: 'GET_PROJECT',
      payload: project
    }

  } else if (response.status === 404) {
    window.location.href = '/not-found'
    return {
      type: 'SET_ERRORS',
      payload: { text: 'Not Found', status: 404 }
    }

  }
}
const clearProjectAction = () => {
  return {
    type: 'GET_PROJECT',
    payload: {}
  }
}

var rand = function () {
  return Math.random();
};

var token = function () {
  return rand() + rand();
};


const shareEvaluationAction = async (evaluationIds: number[], emails: string[]) => {
  const response: Response = await doRequest('post', `api/v1/project/share`, {
    evaluationIds: evaluationIds,
    emails: emails
  })
  const id = token()
  if (response.status === 201) {
    setTimeout(() => {
      removeNotifications([{
        id: id,
        status: 'finished',
        name: ''
      }]);
    }, 5000)
    return {
      type: 'GET_NOTIFICATIONS',
      payload: [{
        id,
        status: 'finished',
        name: '',
        message: `Successfully shared with ${emails.join(',')}`
      }]
    }
  } else {
    let errors = [];
    const res = await response.json();
    if (res.message) {
      errors.push({
        'type': 'error',
        'message': res.message
      })
    } else {
      errors = res.errors;
    }
    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: errors
    }

  }
}

const setModelName = (modelName: string) => {
  return {
    type: 'SET_MODEL_NAME',
    payload: modelName,
  }
}

const setProjectDataAction = (key: string, value: any) => {
  return {
    type: 'SET_PROJECT_DATA',
    payload: {
      key,
      value
    }
  }
}
const setIgnoreLabelIdAction = (key: string, value: string) => {
  return {
    type: 'SET_IGNORE_LABEL_ID',
    payload: {
      value
    }
  }
}
const retryEvaluationAction = async (evaluationId: number) => {
  const response: Response = await doRequest('post', `api/v1/evaluation/${evaluationId}/retry`)
  if (response.status === 200) {
    window.location.reload()
    return {
      type: "RETRY_EVALUATION",
      payload: null
    }
  } else {
    const res = await response.json();
    let errors = [];
    if (res.message) {
      errors.push({
        'type': 'error',
        'message': res.message
      })
    } else {
      errors = res.errors
    }

    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: errors
    }
  }
}

const  clearProjectData = async () => {
  return {
    type: "RESET_NEW_PROJECT",
    payload: null
  }
}


export {
  setProjectDataAction,
  setDataProvider,
  setProjectName,
  setTask,
  setImagesPath,
  setGroundTruthsPath,
  setDetectionsPath,
  setDetectionsMetadataFormat,
  setClassesPath,
  createProject,
  setModelName,
  getProjectAction,
  setNewProjectDataAction,
  shareEvaluationAction,
  setIgnoreLabelIdAction,
  retryEvaluationAction,
  createRoboflowProjectAction,
  clearProjectAction,
  createDatabricksProjectAction,
  clearProjectData,
  clearProjectInputPaths,

}

