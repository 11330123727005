import React, {useEffect, useState} from "react";
import "./styles.scss";
import {
    clearEvaluationData,
    clearEvaluationInputPaths,
    setEvaluationDataAction,
    startEvaluationAction,
} from "../../actions/evaluation";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import InputWithTitle from "../../components/input_with_title";
import InputWithImage from "../../components/input_with_image";
import Button from "../../components/button";
import {setBreadcrumbs} from "../../actions/page";
import {getProjectAction} from "../../actions/project";
import Project from "../../types/project";
import FileUpload from "../../components/file_upload";
import {isEmpty} from "lodash";
import Loading from "../../components/loading";
import {useNavigate} from "react-router";
import Integration from "../../types/integrations";
import {getIntegrations} from "../../actions/user";
import {setErrorAction} from "../../actions/error";
import {s3_validation} from "./validations/s3_validation";
import {databricks_validation} from "./validations/databricks_validation";

type Props = {
    newEvaluation: any;
    setBreadcrumbs: (breadcrumbs: any[]) => void;
    setEvaluationData: (key: string, value: any) => void;
    startEvaluation: (newEvaluation: any) => void;
    project: Project;
    errors: [];
    getProject: (projectId: string) => void;
    integration: any;
    getIntegrations: () => void;
    clearEvaluationInputPaths: () => void;
    clearEvaluationData: () => void;
    setError: (obj: {
        key: string,
        type: string,
        message: string,
    }) => void;
};

type Option = {
    label: string;
    value: number | string;
};

function AddEvaluation({
                           newEvaluation,
                           setEvaluationData,
                           setBreadcrumbs,
                           startEvaluation,
                           project,
                           errors,
                           setError,
                           getProject,
                           integration,
                           getIntegrations,
                           clearEvaluationInputPaths,
                           clearEvaluationData
                       }: Props) {
    const {projectId} = useParams();
    const navigate = useNavigate();
    const [integrationOptions, setIntegrationOptions] = useState([])
    const [isDisabledDataProvider, setIsDisabledDataProvider] = useState(false)


    useEffect(() => {
        clearEvaluationData()
    }, []);

    function groupIntegrations(integrations: Integration[]): any {
        const groupedOptions: { [key: string]: Option[] } = {};

        integrations.forEach(integration => {
            if (integration.type === 'label_studio') {
                return
            }

            if (!groupedOptions[integration.type]) {
                groupedOptions[integration.type] = [];
            }
            groupedOptions[integration.type].push({label: integration.name, value: integration.id});
        });
        return Object.entries(groupedOptions).map(([label, options]) => ({label, options}));
    }


    const validate = () => {
        let valid = true;
        let validation;
        switch (newEvaluation.data_provider) {
            case 's3':
                validation = s3_validation;
                break
            case 'databricks':
                validation = databricks_validation;
                break
        }

        validation && validation.forEach((field) => {
            if (field.validationRule) {
                const regex = new RegExp(field.validationRule);
                let target = newEvaluation[field.fieldName] ? newEvaluation[field.fieldName] : ""
                if (!regex.test(target)) {
                    setError({
                        key: field.fieldName,
                        type: 'validationError',
                        message: field.errorMessage ? field.errorMessage : `Invalid ${field.title}`
                    })
                    valid = false
                }
            }
        })
        return valid
    }

    useEffect(() => {
        const getProject = async () => {
            //@ts-ignore
            const project = await getProjectAction(projectId);
        };
        getProject();
        getIntegrations()
    }, []);
    const [ref, setRef] = useState(null);
    useEffect(() => {
        //@ts-ignore
        setEvaluationData("project_id", parseInt(projectId));
    }, []);

    useEffect(() => {
        // This effect will run whenever the integrations state changes
        setIntegrationOptions(groupIntegrations(integration.integrations));
    }, [integration]);

    useEffect(() => {
        let oldProvider = newEvaluation['data_provider']
        let provider = integration.integrations.find((integration: any) => integration.id === newEvaluation.integration_id)
        if (provider) {
            setEvaluationData('data_provider', provider.type)
            oldProvider !== provider.type && clearEvaluationInputPaths()
        }
    }, [newEvaluation.integration_id]);

    const crumbs = [
        {
            name: "Projects",
            path: "/",
        },
        {
            name: project.name,
            path: `/projects/${project.id}`,
        },
    ];
    const storageOptions = [
        {label: "S3", value: "s3"},
        {label: "Roboflow", value: "roboflow"},
        {label: "Databricks", value: "databricks"},

        // { label: 'GCS', value: 'gcs' },
        // { label: 'Hugging Face', value: 'huggingface' },
        // { label: 'Deeplake', value: 'deeplake' }
    ];
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        //@ts-ignore
        getProject(projectId);
    }, []);

    useEffect(() => {
        if (!isEmpty(project) && project.type !== "public") {
            setBreadcrumbs(crumbs);
        }
    }, [project]);
    if (isEmpty(project)) {
        return <Loading/>;
    }


    if (project.type === "public") {
        navigate("/not_found");
        return null;
    }

    return (
        <div className={"add-project-class"}>
            <div>
                {/*<h1>Add Evaluation</h1>*/}

                <InputWithTitle
                    title={"Evaluation Name"}
                    size={"long"}
                    placeholder={"Evaluation Name"}
                    onChange={setEvaluationData}
                    name={"name"}
                ></InputWithTitle>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                    <InputWithTitle
                        disabled={isDisabledDataProvider}
                        title={"Data Storage"}
                        size={"long"}
                        placeholder={"Data Storage"}
                        type={"select"}
                        options={integrationOptions}
                        value={newEvaluation.data_provider}
                        onChange={setEvaluationData}
                        name={"integration_id"}
                        // extraActionOnChange={clearEvaluationInputPaths}
                    ></InputWithTitle>
                    <p className={"delimiter-classname"}>Or upload your data locally</p>

                    {/*{["s3", "gcs"].includes(newEvaluation.data_provider) && (*/}
                    <div>
                        <div>
                            <FileUpload
                                setLoading={setLoading}
                                type={"evaluation"}
                                setRef={setRef}
                                dataProvider={newEvaluation.data_provider}
                                onClick={() => {
                                    setIsDisabledDataProvider(true)
                                    if (newEvaluation.integration_id !== undefined) {
                                        // @ts-ignore
                                        delete newEvaluation.integration_id
                                    }
                                    // if (newEvaluation.data_provider !== undefined) {
                                    //     delete newEvaluation.data_provider
                                    // }
                                    setEvaluationData('data_provider', 's3')
                                }}
                            ></FileUpload>
                            <Button
                                loading={loading}
                                text={"Upload"}
                                onClick={() => {
                                    //@ts-ignore
                                    ref.current.click();
                                }}
                            ></Button>
                        </div>
                    </div>
                    {/*)}*/}
                </div>
                <p className={"input-group-title-class"}>Input Paths</p>
                {newEvaluation.data_provider !== "roboflow" &&
                    newEvaluation.data_provider !== "databricks" && (
                        <InputWithImage
                            value={newEvaluation.dataPath ? newEvaluation.dataPath : ''}
                            subtitle={"(png, jpg, jpeg)"}
                            size={"long"}
                            type={"text"}
                            title={"Data Path"}
                            placeholder={"/path/to/unlabeled/images"}
                            imagePath={"/image.svg"}
                            onChange={setEvaluationData}
                            name={"dataPath"}
                        ></InputWithImage>
                    )}
                {newEvaluation.data_provider === "deeplake" && (
                    <InputWithTitle
                        title={"Deeplake Token"}
                        size={"long"}
                        placeholder={"Deeplake Token"}
                        type={"password"}
                        onChange={setEvaluationData}
                        name={"deeplake_token"}
                    ></InputWithTitle>
                )}
                {newEvaluation.data_provider == "roboflow" && (
                    <>
                        <InputWithImage
                            size={"long"}
                            type={"text"}
                            title={"Roboflow workspace ID"}
                            validation={"^.{1,}$"}
                            name={"roboflow_workspace_id"}
                            placeholder={"Roboflow Workspace ID"}
                            imagePath={"/image.svg"}
                            onChange={setEvaluationData}
                        ></InputWithImage>
                        <InputWithImage
                            size={"long"}
                            type={"text"}
                            title={"Roboflow project ID"}
                            validation={"^.{1,}$"}
                            name={"roboflow_project_id"}
                            placeholder={"Roboflow Project ID"}
                            imagePath={"/image.svg"}
                            onChange={setEvaluationData}
                        ></InputWithImage>
                        <InputWithImage
                            subtitle={""}
                            size={"long"}
                            type={"text"}
                            title={"Roboflow project version"}
                            validation={"^.{1,}$"}
                            name={"roboflow_project_version"}
                            placeholder={"Roboflow project version"}
                            imagePath={"/image.svg"}
                            onChange={setEvaluationData}
                        ></InputWithImage>
                    </>
                )}
                {newEvaluation.data_provider == "databricks" && (
                    <>
                        <InputWithImage
                            size={"long"}
                            type={"text"}
                            title={"Databricks Workspace URL"}
                            validation={"^.{1,}$"}
                            name={"host"}
                            placeholder={"Databricks Workspace URL"}
                            imagePath={"/image.svg"}
                            onChange={setEvaluationData}
                        ></InputWithImage>
                        <InputWithImage
                            size={"long"}
                            type={"text"}
                            title={"Databricks cluster id"}
                            validation={"^.{1,}$"}
                            name={"cluster_id"}
                            placeholder={"Databricks cluster id"}
                            imagePath={"/image.svg"}
                            onChange={setEvaluationData}
                            tooltipText={"The cluster ID: Cluster ID is the unique ID for a cluster in Databricks. To get the cluster ID, click the Clusters tab in the left pane and then select a cluster name. You can find the cluster ID in the URL of this page <databricks-url>/#/settings/clusters/<cluster-id>/configuration."}
                        ></InputWithImage>
                        <InputWithImage
                            subtitle={""}
                            size={"long"}
                            type={"text"}
                            title={"Catalog name"}
                            validation={"^.{1,}$"}
                            name={"catalogue_name"}
                            placeholder={
                                "Catalog name to store output of evaluation data"
                            }
                            imagePath={"/image.svg"}
                            onChange={setEvaluationData}
                        ></InputWithImage>
                        <InputWithImage
                            subtitle={""}
                            size={"long"}
                            type={"text"}
                            title={"Schema name"}
                            validation={"^.{1,}$"}
                            name={"schema_name"}
                            placeholder={
                                "Schema name to store output of evaluation data"
                            }
                            imagePath={"/image.svg"}
                            onChange={setEvaluationData}
                        ></InputWithImage>
                        <InputWithImage
                            subtitle={""}
                            size={"long"}
                            type={"text"}
                            title={"Table name"}
                            validation={"^.{1,}$"}
                            name={"table_name"}
                            placeholder={"Table name of evaluation data"}
                            imagePath={"/image.svg"}
                            onChange={setEvaluationData}
                        ></InputWithImage>

                    </>
                )}
                <InputWithImage
                    value={newEvaluation.dataPath}
                    subtitle={"Video Step"}
                    size={"long"}
                    type={"number"}
                    title={"Video Step"}
                    placeholder={"Video Step"}
                    imagePath={"/image.svg"}
                    onChange={setEvaluationData}
                    name={"video_step"}
                ></InputWithImage>

                <div className={"button-wrapper-class"}>
                    <Button
                        text={"Back"}
                        onClick={() => {
                            navigate(`/projects/${projectId}`);
                        }}
                    ></Button>
                    <Button
                        text={"Run Evaluation"}
                        onClick={async () => {
                            validate() && await startEvaluation(newEvaluation);
                        }}
                    ></Button>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    newEvaluation: state.newEvaluation,
    project: state.project,
    errors: state.errors,
    integration: state.integration

});

const mapDispatchToProps = (dispatch: any) => ({
    setEvaluationData: (key: string, value: any) =>
        dispatch(setEvaluationDataAction(key, value)),
    setBreadcrumbs: (breadcrumbs: any[]) => {
        dispatch(setBreadcrumbs(breadcrumbs));
    },
    startEvaluation: async (newEvaluation: any) =>
        dispatch(await startEvaluationAction(newEvaluation)),
    getProject: async (projectId: string) => {
        dispatch(await getProjectAction(projectId));
    },
    getIntegrations: async () => dispatch(await getIntegrations()),
    clearEvaluationInputPaths: async () => dispatch(await clearEvaluationInputPaths()),
    setError: (error: any) => {
        dispatch(setErrorAction(error))
    },
    clearEvaluationData: () => dispatch(clearEvaluationData())

});
export default connect(mapStateToProps, mapDispatchToProps)(AddEvaluation);
