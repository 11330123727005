import InputWithTitle from "../../components/input_with_title";
import Box from "@mui/material/Box";
import React from "react";
import {setCredentialsAction} from "../../actions/user";
import {connect} from "react-redux";
import LabelStudioModal from "./labeling_tools/label_studio";

type props = {
    setCredentials: (name: string, value: string) => void;
    newCredentials: any;
}


const AddDataProviderIntegration = ({
                                        setCredentials,
                                        newCredentials,
                                    }: props
) => {

    return (
        <Box className={"credentials-wrapper"}>
            <InputWithTitle
                placeholder={"Name"}
                onChange={setCredentials}
                title={"Name your integration"}
                name={"name"}
            ></InputWithTitle>
            <InputWithTitle
                value={newCredentials.type}
                placeholder={"Labeling Tool"}
                onChange={setCredentials}
                title={"Labeling Tool"}
                name={"type"}
                options={[
                    {
                        label: "Label Studio",
                        value: "label_studio",
                    },
                ]}
                type={"select"}
            ></InputWithTitle>
            {newCredentials.type == "label_studio" && (
                <LabelStudioModal/>
            )}
        </Box>


    )
}

const mapStateToProps = (state: any) => ({
    newCredentials: state.newCredentials,
});

const mapDispatchToProps = (dispatch: any) => ({
    setCredentials: (name: string, value: string) =>
        dispatch(setCredentialsAction(name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDataProviderIntegration);