import React, {useState} from 'react';
import './styles.scss';
import {Props} from '../../types/input';
import Select, {SingleValue} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {cloneDeep, find} from "lodash";
import {connect} from "react-redux";
import {removeErrorAction, setErrorAction} from '../../actions/error'
import Button from "../button";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';

function Input({
                   placeholder,
                   onChange,
                   type = 'text',
                   size = 'large',
                   options,
                   onKeyPress,
                   onBlur,
                   value,
                   name,
                   loading,
                   errors,
                   setError,
                   removeError,
                   validation,
                   disabled,
                   extraActionOnChange,
               }: Props) {
    const foundError: string | undefined = find(errors, (e: { key: string }) => e.key == name)
    const validate = (value: any) => {
        if (validation) {
            const re = RegExp(validation)
            return re.test(value)
        }
        return true;
    }
    const [classes, setClasses] = useState([[]]);
    if (type === 'multiselect') {
        return (<CreatableSelect name={name} placeholder={placeholder} isMulti options={[]} isDisabled={disabled}
                                 classNames={
                                     {
                                         //@ts-ignore
                                         control: ({isFocused,}) => {
                                             if (isFocused) {
                                                 return 'select-option-focused-class'
                                             }
                                             return 'select-option-class'
                                         },
                                         //@ts-ignore
                                         option: ({isSelected, isFocused,}) => {
                                             if (isSelected) {
                                                 return 'option-default-class select-option-selected-class'
                                             }
                                             if (isFocused) {
                                                 return 'option-default-class select-option-hovered-class'
                                             }
                                             return 'option-default-class'

                                         }, //@ts-ignore
                                         multiValue: () => {
                                             return 'multi-value-class'
                                         },
                                         multiValueLabel: () => {
                                             return 'multi-value-label-class'
                                         },
                                         multiValueRemove: () => {
                                             return 'multi-value-remove-class'
                                         }

                                     }}
                                 onChange={(values) => {
                                     extraActionOnChange && extraActionOnChange()
                                     onChange(name, values.map((v: { value: string }) => v.value))
                                     removeError(name)
                                 }}/>
        )
    }
    if (type === 'select') {
        return (<Select name={name} placeholder={placeholder} isDisabled={disabled}
                //@ts-ignore
                        defaultValue={options.find((o: Props.options) => o.value === value)}
                        classNames={
                            {
                                //@ts-ignore
                                control: ({isFocused,}) => {
                                    if (isFocused) {
                                        return 'select-option-focused-class'
                                    }
                                    return `select-option-class  ${foundError && 'error'}`;
                                },
                                //@ts-ignore
                                option: ({isSelected, isFocused}) => {
                                    if (isSelected) {
                                        return 'option-default-class select-option-selected-class'
                                    }
                                    if (isFocused) {
                                        return 'option-default-class select-option-hovered-class'
                                    }
                                    return 'option-default-class'

                                }
                            }}
                        options={options}
                        onChange={({label, value}: SingleValue<any>) => {
                            extraActionOnChange && extraActionOnChange()
                            onChange(name, value)
                            removeError(name)
                        }}></Select>
        )
    } else if (type === 'toggle') {
        return (<div className={'toggle-class'}>
            {

                //@ts-ignore
                options.map((opt, i, arr) => {
                    let cls = '';
                    if (i === 0) {
                        cls += 'first '
                    }
                    if (arr.length - 1 === i) {
                        cls += 'last '
                    }
                    if (i !== 0 && i !== (arr.length - 1)) {
                        cls += 'middle '
                    }
                    if (value === opt.value) {
                        cls += ' selected'
                    }

                    return (<span key={i} className={`toggle-option-class ${cls}  ${foundError && 'error'}`}
                                  onClick={() => {
                                      onChange(name, opt.value)
                                  }}>{opt.label}</span>)
                })
            }
        </div>)
    } else if (type === 'search') {

        return (
            <TextField
                disabled={disabled}
                hiddenLabel
                placeholder={placeholder.toString()}
                type="text"
                value={value}
                variant="outlined"
                color="secondary"
                size="small"
                onChange={(event) => {
                    extraActionOnChange && extraActionOnChange()
                    onChange(name, event.currentTarget.value)
                }}
                fullWidth
                sx={{
                    "& input::placeholder": {
                        fontSize: 14,
                    },
                    "& input": {
                        fontSize: 14,
                    },
                    "& .MuiOutlinedInput-root": {
                        height: "29px",
                        "&.Mui-focused fieldset": {
                            borderColor: "#CA95B3",
                        },
                    },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img
                                alt={"Search icon"}
                                src="/search.svg"
                                width="14px"
                                height="14px"
                            />
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    onBlur: onBlur,
                    onKeyDown: (event) => {
                        if (event.key === "Enter") {
                            onBlur();
                            event.currentTarget.blur();
                        }
                    },
                }}
            />
        );

    } else if (type === 'checkbox') {
        return (<>
                <input placeholder={placeholder.toString()} type={type} disabled={disabled}
                       className={`input-class  input-${size} ${foundError && 'error'} input-checkbox`}
                       value={value}
                       onChange={(e: React.FormEvent<HTMLInputElement>) => {
                           extraActionOnChange && extraActionOnChange()
                           onChange(name, e.currentTarget.checked)
                           if (!validate(e.currentTarget.value)) {
                               setError({
                                   key: name,
                                   type: 'validationError',
                                   message: 'does not match the regex'
                               })
                           } else {
                               removeError(name)
                           }
                       }}
                       onKeyPress={onKeyPress}
                ></input>
            </>
        );
    } else if (type === 'dict-input') {
        return (
            <>
                {classes.map((cls: string[], index) => {
                    return (<div>
                        <input placeholder={placeholder[0]} type={type}
                               className={`input-class  input-dict ${foundError && 'error'}`}
                               value={cls[0]}
                               onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                   extraActionOnChange && extraActionOnChange()
                                   cls[0] = e.currentTarget.value;
                                   onChange(name, classes);
                                   if (!validate(e.currentTarget.value)) {
                                       setError({
                                           key: name,
                                           type: 'validationError',
                                           message: 'does not match the regex'
                                       })
                                   } else {
                                       removeError(name)
                                   }
                               }}
                               onKeyPress={onKeyPress}
                        ></input>
                        &nbsp;
                        <input placeholder={placeholder[1]} type={type}
                               className={`input-class  input-dict ${foundError && 'error'}`}
                               value={cls[1]}
                               onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                   extraActionOnChange && extraActionOnChange()
                                   cls[1] = e.currentTarget.value;
                                   onChange(name, classes);
                                   if (!validate(e.currentTarget.value)) {
                                       setError({
                                           key: name,
                                           type: 'validationError',
                                           message: 'does not match the regex'
                                       });
                                   } else {
                                       removeError(name)
                                   }
                               }}
                               onKeyPress={onKeyPress}
                        ></input>
                        {index !== 0 && (<>&nbsp; <Button text={"Remove"} onClick={() => {
                            const newClasses = cloneDeep(classes);
                            newClasses.splice(index, 1);
                            setClasses(newClasses);

                        }}></Button></>)}
                    </div>)
                })}
                <span>
          <Button text={"Add More"} onClick={() => {

              const newClasses = cloneDeep(classes)
              newClasses.push([])
              setClasses(newClasses)
          }}></Button>
        </span>
            </>
        );
    } else {
        return (<>
                <input placeholder={placeholder.toString()} type={type} disabled={disabled}
                       className={`input-class input-${size} ${foundError && 'error'}`}
                       value={value}
                       onChange={(e: React.FormEvent<HTMLInputElement>) => {
                           extraActionOnChange && extraActionOnChange()
                           onChange(name, e.currentTarget.value)
                           // if (!validate(e.currentTarget.value)) {
                           //   setError({
                           //     key: name,
                           //     type: 'validationError',
                           //     message: `Invalid ${placeholder}`
                           //   })
                           // } else {
                           //   removeError(name)
                           // }
                       }}
                       onKeyPress={onKeyPress}
                ></input>
            </>
        );

    }
}

const mapStateToProps = (state: any) => ({
    errors: state.errors
});

const mapDispatchToProps = (dispatch: any) => ({
    setError: (error: any) => dispatch(setErrorAction(error)),
    removeError: (name: string) => dispatch(removeErrorAction(name))
});

export default connect(mapStateToProps, mapDispatchToProps)(Input);