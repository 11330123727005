import { authHeaderKeys, doRequest } from "../helpers/request_helper";
import { snakeCaseKeys } from "../helpers/snake_case_keys";
import {validateEmpty, validateEmail} from "../utils/validators";

const setGoogleCredentials = (token: string) => {
  localStorage.setItem('Access-Token', token);
}

const signIn = async (email: string, password: string, projectToken: string, navigate: any) => {
  let errors = [];

  if (!validateEmpty(password)){
    errors.push({
      'type': 'error',
      'message': "Password cannot be empty"
    })
  }
  if (!validateEmail(email)){
    errors.push({
      'type': 'error',
      'message': "Wrong email format"
    })
  }

  if (errors.length){
    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: errors
    }
  }
  const response: Response = await doRequest('post', 'api/v1/user/sign-in', {
    email: email,
    password: password,
    project_token: projectToken
  })

  if (response && response.status === 200) {
    authHeaderKeys.forEach(key => {
      //@ts-ignore
      localStorage.setItem(key, response.headers.get(key));
    });
    navigate('/')
    return {
      type: "LOGIN",
      payload: {}
    }

  } else {
    const res = await response.json();
    if (res.message) {
      errors.push({
        'type': 'error',
        'message': res.message
      })
    } else {
      errors = res.errors
    }

    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: errors
    }
  }
}
const googleSignIn = async () => {
  const response: Response = await doRequest('get', 'api/v1/user/sign-in/google')

  if (response && response.status === 200) {
    //@ts-ignore
    const auth_url = await response.json()
    window.location.href = auth_url['authorization_url']
  }

}

const signOut = () => {
  const location = window.location.href
  if (!location.includes('sign-in')) {
    localStorage.setItem("lastUrl", location)
  }
  authHeaderKeys.forEach(key => {
    localStorage.removeItem(key);
  });
  if (!location.includes('sign-in')) {
    window.location.href = ('/sign-in')
  }
}

const signUpAction = async (email: string,
                            firstName: string,
                            lastName: string,
                            password: string,
                            confirmPassword: string,
                            companyName: string,
                            role: string,
                            focusArea: string,
                            focusAreaOther: string,
                            navigate: any) => {

  const response: Response = await doRequest('post', 'api/v1/user/sign-up', snakeCaseKeys({
    firstName, lastName, email, password, confirmPassword, companyName, role, focusArea, focusAreaOther
  }))
  if (response.status === 201 || response.status === 200) {
    navigate('/sign-in')
    return {
      type: 'ADD_NOTIFICATIONS',
      payload: {name: '', message: 'Account created successfully', status:'finished'}
    }
  } else {
    const res = await response.json();
    let errors = [];
    if (res.message) {
      errors.push({
        'type': 'error',
        'message': res.message
      })
    } else {
      errors = res.errors
    }

    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: errors
    }
  }
}

const forgetPasswordAction = async (email: string) => {
  const response: Response = await doRequest('post', `api/v1/user/forgot-password`, { email })
  if (response.status === 200) {
    return {
      type: "FORGET_PASSWORD",
      payload: { success: true }
    }

  } else {
    const res = await response.json();
    let errors = [];
    if (res.message) {
      errors.push({
        'type': 'error',
        'message': res.message
      })
    } else {
      errors = res.errors
    }

    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: errors
    }
  }
}

const verifyPasswordToken = async (token: string | null) => {
  const response: Response = await doRequest('get', `api/v1/user/verify-password/${token}`)
  if (response.status === 200) {
    authHeaderKeys.forEach(key => {
      //@ts-ignore
      localStorage.setItem(key, response.headers.get(key));
    });
    return true
  } else {
    return false
  }
}

const updatePassword = async (password: string, confirmPassword: string, token: string | null) => {
  const response: Response = await doRequest('put', `api/v1/user/reset-password/`, snakeCaseKeys({
      password,
      confirmPassword
    }), { 'token': token }
  )
  if (response.status === 200) {
    signOut();
    window.location.href = '/sign-in'
  } else {
    const res = await response.json();
    let errors = [];
    if (res.message) {
      errors.push({
        'type': 'error',
        'message': res.message
      })
    } else {
      errors = res.errors
    }
    return {
      type: "SET_ERRORS",
      //@ts-ignore
      payload: errors
    }
  }
}

export {
  signIn,
  signOut,
  signUpAction,
  forgetPasswordAction,
  verifyPasswordToken,
  updatePassword,
  authHeaderKeys,
  googleSignIn,
  setGoogleCredentials
}

