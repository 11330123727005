import { apiUrl } from "../app_config";
import { forEach, isEmpty } from "lodash";

export const authHeaderKeys = ['Access-Token'];

const buildAuthHeaders = () => {
  let authHeaders = {}
  authHeaderKeys.forEach((headerKey: string) => {
    const header = localStorage.getItem(headerKey)
    //@ts-ignore
    header && (authHeaders[headerKey] = header)
  })
  //@ts-ignore

  return authHeaders;
}

const doRequest = async (method: string, endpoint: string, data: any = {}, headers: any = {}, file: File | null = null, files: FileList | null = null,) => {
  let formData = new FormData()
  if (file) {
    formData.append("file", file)
  }
  if (!isEmpty(files)) {
    //@ts-ignore
    Array.from(files).forEach((file: File) => {
      formData.append("files", file);
    });
  }
  forEach(data, (value, key) => {
    formData.append(key, value);
  });
  let requestData: any = null;
  if (!file && isEmpty(files) && !isEmpty(data)) {
    requestData = {};
    formData.forEach((value, key) => {
      //@ts-ignore
      requestData[key] = value;
    })
    requestData = JSON.stringify(requestData);
    headers["content-type"] = "Application/json";
  } else if (file || !isEmpty(files)) {
    requestData = formData;
  }

  headers = { ...headers, ...buildAuthHeaders() }
  if (endpoint !== 'api/v1/user/sign-in') {
    headers['Authorization'] = `Bearer ${localStorage.getItem('Access-Token')}`
  }

  let response: any = null;
  try {
    response = await fetch(`${apiUrl}/${endpoint}`, {
      method: method,
      headers: headers,
      body: requestData
    });
    if (response.status === 401 && (!endpoint.includes('verify-password') && !endpoint.includes('sign-in'))) {

      window.location.href = ('/sign-in')
    }

    return response
  } catch (error) {

    return {
      json: () => {
        return { message: 'Something went wrong' }
      }
    }
  }


}
export { doRequest }