const validateEmail = (email: string) => {
    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
};


const validateEmpty = (text: string) => {

    // Return false if the text is empty
    return !(text.trim() === '')
}

export {
    validateEmail,
    validateEmpty
}