import InputWithTitle from "../../../components/input_with_title";
import React from "react";
import {setCredentialsAction} from "../../../actions/user";
import {connect} from "react-redux";
import Box from "@mui/material/Box";


type props = {
    setCredentials: (name: string, value: string) => void;
}


const LabelStudioModal = ({setCredentials}: props) => {
    return (
        <Box paddingTop={2}>
            <InputWithTitle
                placeholder={"Label Studio URL"}
                onChange={setCredentials}
                title={"Label Studio URL "}
                name={"url"}
            ></InputWithTitle>
            <InputWithTitle
                placeholder={"Label Studio Token"}
                onChange={setCredentials}
                title={"Label Studio Token"}
                name={"token"}
            ></InputWithTitle>
        </Box>
    )
}

const mapStateToProps = (state: any) => ({
    newCredentials: state.newCredentials,
});

const mapDispatchToProps = (dispatch: any) => ({
    setCredentials: (name: string, value: string) =>
        dispatch(setCredentialsAction(name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelStudioModal)