import React, {useEffect, useState} from "react";
import "./styles.scss";
import {
    clearProjectData,
    createProject,
    setIgnoreLabelIdAction,
    setProjectDataAction,
    clearProjectInputPaths
} from "../../actions/project";
import {connect, useSelector} from "react-redux";
import InputWithTitle from "../../components/input_with_title";
import Button from "../../components/button";
import {setBreadcrumbs} from "../../actions/page";
import FileUpload from "../../components/file_upload";
import NewProject from "../../types/new_project";
import {useNavigate} from "react-router";
import AuthErrors from "../../components/auth_errors";
import {Grid, Card, CardMedia, CardContent, Typography, Box, Autocomplete, TextField} from '@mui/material';
import Integration from "../../types/integrations";
import {getIntegrations} from "../../actions/user";
import S3Import from "./s3_import";
import DatabricksImport from "./databricks_import";
import {setErrorAction} from "../../actions/error";


type Props = {
    projectName: string;
    newProject: NewProject;
    setBreadcrumbs: (breadcrumbs: any[]) => void;
    createProject: (newProject: any, navigate: any) => void;
    setProjectData: (name: string, value: any) => void;
    setIgnoreLabelId: (name: string, value: any) => void;
    integration: any;
    getIntegrations: () => void;
    clearProjectData: () => void;
    clearProjectInputPaths: () => void;
    errors: [];
    setError: (obj: {
        key: string,
        type: string,
        message: string,
    }) => void;
};


type Option = {
    label: string;
    value: number;
};


function AddProject({
                        newProject,
                        setProjectData,
                        createProject,
                        setBreadcrumbs,
                        errors,
                        setError,
                        setIgnoreLabelId,
                        integration,
                        getIntegrations,
                        clearProjectData,
                        clearProjectInputPaths
                    }: Props) {
    const [ref, setRef] = useState(null);
    const [selected, setSelected] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [integrationOptions, setIntegrationOptions] = useState([])
    const [isDisabledDataProvider, setIsDisabledDataProvider] = useState(false)

    function groupIntegrations(integrations: Integration[]): any {
        const groupedOptions: { [key: string]: Option[] } = {};

        integrations.forEach(integration => {
            if (integration.type === 'label_studio') {
                return
            }

            if (!groupedOptions[integration.type]) {
                groupedOptions[integration.type] = [];
            }
            groupedOptions[integration.type].push({label: integration.name, value: integration.id});
        });

        return Object.entries(groupedOptions).map(([label, options]) => ({label, options}));
    }


    useEffect(() => {
        // This effect will run whenever the integrations state changes
        setIntegrationOptions(groupIntegrations(integration.integrations));
    }, [integration]);

    useEffect(() => {
        let oldProvider = newProject.dataProvider
        let provider = integration.integrations.find((integration: any) => integration.id === newProject.integration_id)
        if (provider){
            setProjectData('dataProvider', provider.type)
            oldProvider !== provider.type && clearProjectInputPaths()
        }
    }, [newProject.integration_id]);

    useEffect(() => {
        clearProjectData()
        getIntegrations()
        setSelected('detection')
    }, []);


    const crumbs = [
        {
            name: "Projects",
            path: "/",
        },
        {
            name: "Add Project",
            path: "/add-project",
        },
    ];
    const toggleOptions = [
        {
            label: "Object Detection",
            value: "detection",
            icon: "/detection.svg",
            text: "The box represents a bench."
        },
        {
            label: "Segmentation",
            value: "segmentation",
            icon: "/segmentation.svg",
            text: "The pixels represent a tree."

        },
        {
            label: "Classification",
            value: "classification",
            icon: "/classification.svg",
            text: "The image represents a man walking a dog."

        },
    ];
    const metadataOptions = [
        {label: "cxcywh", value: "cxcywh"},
        {label: "xywh", value: "xywh"},
        {label: "xyx2y2", value: "xyx2y2"},
    ];
    const labelsOptions = [
        {label: "PascalVOC", value: "PascalVOC"},
        {label: "YOLO", value: "YOLO"},
        {label: "COCO", value: "COCO"},
    ];


    useEffect(() => {
        setBreadcrumbs(crumbs);
    });
    const [ignoreLabel, setIgnoreLabel] = useState(false);

    return (
        <div className={"add-project-class"}>
            <div>
                <div className={"project-info-class"}>
                    <InputWithTitle
                        title={"Project Name"}
                        size={"long"}
                        placeholder={"Project Name"}
                        name={"name"}
                        validation={"^.{1,}$"}
                        onChange={setProjectData}
                    ></InputWithTitle>
                    <InputWithTitle
                        title={"Project Description"}
                        size={"long"}
                        placeholder={"Project Description"}
                        name={"description"}
                        validation={"^.{1,1000}$"}
                        onChange={setProjectData}
                    ></InputWithTitle>
                    <Grid container spacing={2} justifyContent="space-between" marginTop={2}>
                        {/* Map through the items array to render each item */}
                        {toggleOptions.map(item => (
                            <Grid key={item.label} item>
                                <Card
                                    className={`select-project-type ${selected === item.value ? 'selected-item' : ''}`}
                                    onClick={() => {
                                        setSelected(item.value)
                                        setProjectData('task', item.value)
                                    }}
                                    style={{height: '300px', width: '295px'}}
                                >
                                    <CardMedia className={'card-media'}
                                               image={item.icon}
                                               title={item.label}
                                               style={{height: '210px'}}

                                    />
                                    <CardContent>
                                        <Typography className={'italic-text'} variant="body2" color="black"
                                                    component="p" fontWeight={'bolder'} fontSize={'20px'}
                                                    align={'left'}>
                                            <b style={{fontWeight: 'bold'}}>{item.label}</b>
                                            <br/>
                                        </Typography>
                                        <Typography className={'italic-text'} variant={'body2'}
                                                    fontSize={'13px'} align={'left'}>
                                            <a style={{marginTop: '10px', marginBottom: '2px'}}>{item.text}</a>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <div>
                        <Box marginTop={2}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                                <InputWithTitle
                                    disabled={isDisabledDataProvider}
                                    title={"Data Provider"}
                                    size={"long"}
                                    placeholder={"Data Storage"}
                                    type={"select"}
                                    options={integrationOptions}
                                    value={newProject.integration_id}
                                    onChange={setProjectData}
                                    name={"integration_id"}
                                    // extraActionOnChange={clearProjectInputPaths}
                                ></InputWithTitle>
                                <p className={"delimiter-classname"}>Or upload your data locally</p>
                                <div>
                                    <div>
                                        <FileUpload
                                            setLoading={setLoading}
                                            type={"project"}
                                            setRef={setRef}
                                            dataProvider={newProject.dataProvider}
                                            task={newProject.task}
                                            onClick={() => {
                                                setIsDisabledDataProvider(true)
                                                if (newProject.integration_id !== undefined) {
                                                    // @ts-ignore
                                                    delete newProject.integration_id
                                                }
                                                // if (newProject.dataProvider !== undefined) {
                                                //     // @ts-ignore
                                                //     delete newProject.dataProvider
                                                // }
                                                setProjectData('data_provider', 's3')

                                            }}
                                        ></FileUpload>
                                        <Button
                                            loading={loading}
                                            text={"Upload"}
                                            onClick={() => {
                                                //@ts-ignore
                                                ref.current.click();
                                            }}
                                        ></Button>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </div>
                    <p className={"delimiter-classname"}></p>

                    {(() => {
                        switch (newProject.dataProvider) {
                            case 'databricks':
                                return < DatabricksImport
                                    newProject={newProject}
                                    setProjectData={setProjectData}
                                    createProject={createProject}
                                    errors={errors}
                                    setError={setError}
                                    integration={integration}
                                    toggleOptions={toggleOptions}
                                    loading={loading}/>;
                            default:
                                return <S3Import
                                    newProject={newProject}
                                    setProjectData={setProjectData}
                                    createProject={createProject}
                                    errors={errors}
                                    setError={setError}
                                    setIgnoreLabelId={setIgnoreLabelId}
                                    metadataOptions={metadataOptions}
                                    labelsOptions={labelsOptions}
                                    setIgnoreLabel={setIgnoreLabelId}
                                    ignoreLabel={ignoreLabel}
                                    loading={loading}
                                    clearProjectData={clearProjectData}
                                    integration={integration}/>;
                        }
                    })()}

                </div>
            </div>

            <AuthErrors></AuthErrors>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    projectName: state.newProject.projectName,
    newProject: state.newProject,
    errors: state.errors,
    integration: state.integration
});

const mapDispatchToProps = (dispatch: any) => ({
    setProjectData: (key: string, value: any) =>
        dispatch(setProjectDataAction(key, value)),
    createProject: async (newProject: any, navigate: any) =>
        dispatch(await createProject(newProject, navigate)),
    setIgnoreLabelId: async (key: string, value: string) =>
        dispatch(setIgnoreLabelIdAction(key, value)),
    setBreadcrumbs: (breadcrumbs: any[]) => {
        dispatch(setBreadcrumbs(breadcrumbs));
    },
    getIntegrations: async () => dispatch(await getIntegrations()),
    clearProjectData: async () => dispatch(await clearProjectData()),
    clearProjectInputPaths: async () => dispatch(await clearProjectInputPaths()),
    setError: (error: any) => {
        dispatch(setErrorAction(error))
    },

});

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);
