import React, {useEffect, useState} from "react";
import "./styles.scss";
import User from "../../types/user";
import Integration from "../../types/integrations";
import Button from "../../components/button";
import AddDataProviderIntegration from "./add_dataprovider_integration";
import AddLabelingToolIntegration from "./add_labelingtool_integration";
import {
    clearCredentialsAction,
    getUserAction,
    setCredentialsAction,
    updateDataProviderCredentialsAction,
    getIntegrations,
    deleteIntegration
} from "../../actions/user";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {connect} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {sendToLabelingAction} from "../../actions/evaluation";
import {useNavigate} from "react-router";
import Link from "@mui/material/Link";
import MultiActionAreaCard from "../../components/CardMui";


type Props = {
    getUser: () => void;
    user: User;
    setCredentials: (name: string, value: string) => void;
    updateDataProviderCredentials: (newCredentials: any) => void;
    clearCredentials: () => void;
    newCredentials: any;
    getIntegrations: () => void;
    deleteIntegration: (integration_id: number) => void;
    integration: Integration[];
};

const Integrations = ({
                          getUser,
                          user,
                          setCredentials,
                          updateDataProviderCredentials,
                          newCredentials,
                          clearCredentials,
                          getIntegrations,
                          integration,
                          deleteIntegration,

                      }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [value, setValue] = useState(
        searchParams.get("selected") == "labelingTools" ? 1 : 0
    );

    const [type, setType] = useState(
        searchParams.get("selected") == "labelingTools"
            ? "labelingTools"
            : "dataProviders"
    );
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // setCredentials("type", "s3");
        getIntegrations()
        getUser();
    }, []);
    if (!user) {
        return null;
    }


    const handleChange = (event: unknown, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                overflowX: 'auto',
                maxWidth: '100%',
                maxHeight: 'calc(2 * 140px + 2 * 16px)'
            }}>
                {/*@ts-ignore*/}
                {Object.values(integration).map((items: {
                    id: number,
                    type: string,
                    name: string
                }[], index: number) => (
                    <React.Fragment key={index}>
                        {items.map((item, itemIndex) => (
                            <div key={itemIndex} style={{
                                flex: '0 0 auto',
                                paddingRight: '10px'
                            }}> {/* Set a fixed size for each card */}
                                <MultiActionAreaCard key={itemIndex} id={item.id} deleteAction={deleteIntegration}
                                                     title={item.name ? item.name : "Untitled"}
                                                     body={`Type: ${item.type}`}/>
                            </div>

                        ))}
                    </React.Fragment>
                ))}
            </div>
            <>

                <Box width={"50%"} marginY={6}>
                    <AppBar
                        position="static"
                        color="default"
                        sx={{boxShadow: "none", borderRadius: "6px"}}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="action tabs example"
                        >
                            <Tab
                                label="Providers"
                                disableFocusRipple
                                disableTouchRipple
                                onClick={() => {
                                    setType("dataProviders");
                                    clearCredentials();
                                }}
                            />
                            <Tab
                                label="Labeling Tools"
                                disableFocusRipple
                                disableTouchRipple
                                onClick={() => {
                                    setType("labelingTools");
                                    clearCredentials();
                                    setCredentials("type", "label_studio");
                                }}
                            />
                        </Tabs>
                    </AppBar>

                </Box>

                {type === "dataProviders" && (
                    <AddDataProviderIntegration/>
                )}
                {type === "labelingTools" && (
                    <AddLabelingToolIntegration/>
                )}

                <Button
                    loading={loading}
                    text={"Update"}
                    onClick={async () => {
                        setLoading(true);
                        await updateDataProviderCredentials(newCredentials);
                        getIntegrations()
                        clearCredentials()
                        setLoading(false);

                    }}
                ></Button>
                {type === "labelingTools" && (
                    <Box fontSize={"14px"} paddingTop={2}>
                        Don’t have an account?
                        <Link
                            href={process.env.REACT_APP_LABEL_STUDIO_URL}
                            target={"_blank"}
                            paddingLeft={1}
                        >
                            Create it in the Label Studio
                        </Link>
                    </Box>
                )}
            </>
            {/*</Card>*/}
        </Box>
    );
}

const mapStateToProps = (state: any) => ({
    user: state.user,
    integration: state.integration,
    newCredentials: state.newCredentials,
});

const mapDispatchToProps = (dispatch: any) => ({
    getUser: async () => dispatch(await getUserAction()),
    setCredentials: (name: string, value: string) =>
        dispatch(setCredentialsAction(name, value)),
    clearCredentials: () => dispatch(clearCredentialsAction()),
    updateDataProviderCredentials: async (newCredentials: any) =>
        dispatch(await updateDataProviderCredentialsAction(newCredentials)),
    getIntegrations: async () => dispatch(await getIntegrations()),
    deleteIntegration: async (integration_id: number) => dispatch(await deleteIntegration(integration_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
