import React, { useEffect, useState } from "react";
import "./styles.scss";
import Button from "../../components/button";
import Input from "../../components/input";
import { googleSignIn, signIn, signOut } from "../../actions/auth";
import { connect } from "react-redux";
import AuthErrors from "../../components/auth_errors";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Notification from "../../components/notification";
import Box from "@mui/material/Box";

type Props = {
    signInAction: any;
    errors: [];
    notifications: [];
};

function SignIn({ signInAction, errors, notifications }: Props) {
    // signOut()
    const [searchParams, setSearchParams] = useSearchParams();
    const projectToken = searchParams.get("project_token");

    useEffect(() => {
        signOut();
    }, []);
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    //@ts-ignore
    const [authType, setAuthType] = useState(process.env.REACT_APP_AUTH);
    const handleSubmit = async () => {
        setLoading(true);
        await signInAction(email, password, projectToken, navigate);

        setLoading(false);
    };

    const handleKeyPress = async (event: any) => {
        if (event.key === "Enter") {
            await handleSubmit();
        }
    };

    return (
        <div className={"auth-container-class"}>
            <p className={"page-header-class"}>Sign In</p>
            {authType === "password" && (
                <>
                    <Input
                        placeholder={"Email"}
                        onChange={(name, value) => {
                            setEmail(value);
                        }}
                        name={"email"}
                        onKeyPress={handleKeyPress}
                    />

                    <Input
                        type={"password"}
                        placeholder={"Password"}
                        onChange={(name, value) => {
                            setPassword(value);
                        }}
                        name={"password"}
                        onKeyPress={handleKeyPress}
                    />
                    <a
                        className={"forgot-password-link-class"}
                        href="/forgot-password"
                    >
                        {" "}
                        Forgot password ?{" "}
                    </a>
                    <div></div>
                    <Button
                        text={"Log In"}
                        onClick={handleSubmit}
                        loading={loading}
                    />
                </>
            )}
            {authType !== "password" && (
                <Button
                    text={"SSO Sign-in"}
                    onClick={() => {
                        googleSignIn();
                    }}
                />
            )}
            {authType == "password" && (
                <div>
                    {" "}
                    Don't have an account ?{" "}
                    <a className={"create-account-class"} href={"/sign-up"}>
                        {" "}
                        Create Account
                    </a>
                </div>
            )}
            <Box className={"notifications-wrapper-class"}>
                <AuthErrors></AuthErrors>
                {notifications.map(
                    (
                        notification: {
                            task?: string;
                            id?: number;
                            status: string;
                            name: string;
                            message: string;
                        },
                        index: number
                    ) => {
                        return (
                            <Notification
                                task={notification.task}
                                id={notification.id}
                                status={notification.status}
                                name={notification.name}
                                message={notification.message}
                            ></Notification>
                        );
                    }
                )}
            </Box>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    errors: state.errors,
    notifications: state.notifications,
});
const mapDispatchToProps = (dispatch: any) => ({
    signInAction: async (
        email: string,
        password: string,
        projectToken: string,
        navigate: any
    ) => {
        dispatch(await signIn(email, password, projectToken, navigate));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
