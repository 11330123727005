import React from 'react';
import './styles.scss';

function Loading() {
  return (<div className={'loading-class'}>
      <img src={'/loading.svg'}/>
      <p>Loading...</p>
    </div>
  );
}

export default Loading;
