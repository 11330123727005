import React, {useState} from 'react';
import './styles.scss';
import Button from "../../components/button";
import {signUpAction} from "../../actions/auth";
import Step1 from "./step_1";
import {connect} from "react-redux";
import AuthErrors from "../../components/auth_errors";
import {setErrorAction} from "../../actions/error";
import {fieldProps} from "../../types/create_account";
import {addNotification, removeNotifications} from "../../actions/notifications";
import {useNavigate} from "react-router";

type Props = {
    signUp: any;
    setError: (obj: {
        key: string,
        type: string,
        message: string,
    }) => void;
    addNotification: (notification_text: string, message: string, status: string) => void;
    removeNotifications: () => void;
}

function SignUp({signUp, setError, addNotification, removeNotifications}: Props) {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [role, setRole] = useState('');
    const [focusArea, setFocusArea] = useState('');
    const [focusAreaOther, setFocusAreaOther] = useState('');

    const navigate = useNavigate();

    const fields: fieldProps = [
        {
            name: "email",
            value: email,
            setValue: setEmail,
            placeholder: "Email",
            validation: "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
            errorMessage: "Invalid Email"
        },
        {
            name: "first_name",
            value: firstName,
            setValue: setFirstName,
            placeholder: "First Name",
            validation: '^.+$',
            errorMessage: "First Name cannot be empty"
        },
        {
            name: "last_name",
            value: lastName,
            setValue: setLastName,
            placeholder: "Last Name",
            validation: '^.+$',
            errorMessage: "Last Name cannot be empty"
        },
        {
            name: "password",
            value: password,
            setValue: setPassword,
            placeholder: "Password",
            validation: "^.{8,}$",
            type: 'password',
            errorMessage: "Password should be at least 8 characters long"
        },
        {
            name: "confirm_password",
            value: confirmPassword,
            setValue: setConfirmPassword,
            placeholder: "Confirm Password",
            type: 'password'
        }
    ]

    const handleSubmit = async (event: any) => {


        if (validate()) {
            setTimeout(() => {
                removeNotifications();
            }, 5000)
            await signUp(email, firstName, lastName, password, confirmPassword, companyName, role, focusArea, focusAreaOther, navigate)
        }
    };

    const validate = () => {
        let valid = true;
        let password: string = ''
        let confirm_password: string = ''
        fields.forEach((field) => {
            if (field.validation) {
                const regex = new RegExp(field.validation);
                if (!regex.test(field.value)) {
                    setError({
                        key: field.name,
                        type: 'validationError',
                        message: field.errorMessage ? field.errorMessage : `Invalid ${field.placeholder}`
                    })
                    valid = false
                }
            }
            if (field.name === 'password') {
                password = field.value
            }
            if (field.name === 'confirm_password') {
                confirm_password = field.value
            }
        });
        if (!valid) {
            return false
        } else if (password !== confirm_password) {
            setError({
                key: 'password',
                type: 'validationError',
                message: 'Passwords do not match'
            })
            return false
        }
        return true

    }


    return (<div className={'auth-container-class'}>
            <Step1 fields={fields}/>

            <Button text={'Create account'} onClick={handleSubmit}/>
            <div> Have an account ? <a className={'sing-in-class'} href={'/sign-in'}> Sign In</a></div>
            <AuthErrors></AuthErrors>
        </div>
    );
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
    signUp: async (
        email: string,
        firstName: string,
        lastName: string,
        password: string,
        confirmPassword: string,
        companyName: string,
        role: string,
        focusArea: string,
        focusAreaOther: string,
        navigate: any
    ) => {
        dispatch(await signUpAction(email, firstName, lastName, password, confirmPassword, companyName, role, focusArea, focusAreaOther, navigate))
    },
    setError: (error: any) => {
        dispatch(setErrorAction(error))
    },
    addNotification: (notification_text: string, message: string, status: string) => {
        (dispatch(addNotification(notification_text, message, status)))
    },
    removeNotifications: () => (dispatch(removeNotifications())),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);