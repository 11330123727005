import React, { useEffect } from "react";
import "./styles.scss";
import User from "../../types/user";
import { getUserAction } from "../../actions/user";

import { connect } from "react-redux";
import { useNavigate } from "react-router";
import Card from "../../components/card";
import Box from "@mui/material/Box";

type Props = {
    getUser: () => void;
    user: User;
};

function Account({ getUser, user }: Props) {
    const navigate = useNavigate();

    useEffect(() => {
        getUser();
    }, []);

    if (!user) {
        return null;
    }

    return (
        <Card
            title={"Account Details"}
            infoText="Here is information on your account. To request an update to this information, fill out the `Contact Us` form."
        >
            <Box fontSize={14}>
                <Box>
                    <span
                        style={{
                            fontWeight: "500",
                        }}
                    >
                        First Name
                    </span>
                    : {user.first_name}
                </Box>
                <Box marginY={2}>
                    <span
                        style={{
                            fontWeight: "500",
                        }}
                    >
                        Last Name
                    </span>
                    : {user.last_name}
                </Box>
                <Box>
                    <span
                        style={{
                            fontWeight: "500",
                        }}
                    >
                        Email
                    </span>
                    : {user.email}
                </Box>
            </Box>
        </Card>
    );
}

const mapStateToProps = (state: any) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch: any) => ({
    getUser: async () => dispatch(await getUserAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
