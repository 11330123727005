import {cloneDeep, find, findIndex, orderBy} from "lodash";
import Notification from "./types/notification";
import Project from "./types/project";
import {EvaluationModel} from "./types/evaluation";
import humanizeString from 'humanize-string';
import store from "./store";
import Integration from "./types/integrations";
import new_project from "./types/new_project";

interface InitialState {
    newProject: {
        name?: string
        task: string,
        generate_insights: boolean,
        use_datalake: boolean,
        classes: object,
        integration_id?: number
        dataProvider?: string,
    };
    project: Project,
    forgetPasswordSent: false,
    breadcrumbs: [],
    notifications: [],
    errors: [],
    projects: null | Project[],
    privateProjects: null | Project[],
    evaluations: [],
    newCredentials: any
    newEvaluation: {
        name?: string,
        data_provider: string,
        integration_id?: number,
        project_id?: number,
    },
    evaluation: null | EvaluationModel,
    clusters: [],
    integration: { integrations: Integration[] }

}

const initialState: InitialState = {
    newProject: {task: 'detection', generate_insights: false, use_datalake: false, classes: {}},
    project: {} as Project,
    breadcrumbs: [],
    notifications: [],
    forgetPasswordSent: false,
    errors: [],
    projects: null,
    privateProjects: null,
    evaluations: [],
    newCredentials: {},
    newEvaluation: {
        data_provider: 's3',
    },
    evaluation: null,
    clusters: [],
    integration: {integrations: []}
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state
            }
        case 'SET_IGNORE_LABEL_ID':
            return {
                ...state,
                newProject: {
                    ...state.newProject,
                    classes: {...state.newProject.classes, ignore_label: action.payload.value}
                }
            };
        case 'RESET_NEW_PROJECT':
            return {
                ...state,
                newProject: {task: 'detection', generate_insights: false, use_datalake: false, classes: {}},
            }
        case 'SET_CREDENTIALS':
            return {
                ...state,
                newCredentials: {...state.newCredentials, ...{[action.payload.name]: action.payload.value}}
            }
        case 'CLEAR_CREDENTIALS':
            return {
                ...state,
                newCredentials: {}
            }
        case 'CLEAR_PROJECT_INPUT_PATHS':
            return {
                ...state,
                newProject: {
                    name: state.newProject.name,
                    task: state.newProject.task,
                    integration_id: state.newProject.integration_id,
                    dataProvider: state.newProject.dataProvider,
                    generate_insights: false,
                    use_datalake: false,
                    classes: {}
                }
            }
        case 'CLEAR_EVALUATION_INPUT_PATHS':
            return {
                ...state,
                newEvaluation: {
                    name: state.newEvaluation.name,
                    integration_id: state.newEvaluation.integration_id,
                    project_id: state.newEvaluation.project_id,
                    data_provider: state.newEvaluation.data_provider
                }
            }
        case 'RESET_NEW_EVALUATION':
            return {
                ...state,
                newEvaluation: {data_provider: 's3'},
            }
        case 'GET_USER':
            return {
                ...state,
                user: action.payload
            }
        case 'GET_INTEGRATIONS':
            const integrations: Integration[] = action.payload
            return {
                ...state,
                integration: {...state.integration, integrations}
            }
        case 'DELETE_INTEGRATION':
            const integrationId: number = action.payload

            // Find the index of the integration with the specified ID
            const updatedIntegrations = state.integration.integrations.filter(integration => integration.id !== integrationId);
            return {
                ...state,
                integration: {integrations: updatedIntegrations}
            }
        case 'UPDATE_DATA_PROVIDER':
            return {
                ...state,
                integration: {...state.integration,}
            }
        case 'FORGET_PASSWORD':
            return {
                ...state,
                forgetPasswordSent: true,
            }
        case 'SET_PROJECT_DATA':
            if (action.payload.key == 'classes') {

                let classes = state.newProject.classes;
                action.payload.value.forEach((cls: string[]) => {
                    if (cls.length == 2 && cls[0]) {
                        //@ts-ignore
                        classes[cls[0]] = cls[1];
                    }
                });
                action.payload.value = classes;
            }
            return {
                ...state,
                newProject: {...state.newProject, [action.payload.key]: action.payload.value}
            }
        case 'SET_EVALUATION_DATA':
            return {
                ...state,
                newEvaluation: {...state.newEvaluation, [action.payload.key]: action.payload.value}
            }
        case 'REMOVE_ERRORS':
            let errorsCopy = cloneDeep(state.errors);
            errorsCopy.splice(0, action.payload)
            return {
                ...state,
                errors: errorsCopy
            }
        case 'SET_ERRORS':
            const errors = action.payload ?
                action.payload.map((error: any) => {
                    if (error.message == 'field required') {
                        error.message = 'field required ' + humanizeString(error.key)
                    }
                    return error
                }) :
                [
                    {
                        "type": "error",
                        "message": "Something went wrong"
                    }
                ];
            setTimeout(() => {
                store.dispatch({
                    type: "REMOVE_ERRORS",
                    payload: errors.length
                })
            }, 5000)

            return {
                ...state,
                errors: [...state.errors, ...errors]
            }
        case 'SET_ERROR':
            if (find(state.errors, (e: { name: string }) => {
                return e.name == action.payload.name
            })) {
                return state
            }
            //@ts-ignore
            state.errors.push(action.payload)
            setTimeout(() => {
                store.dispatch({
                    type: "REMOVE_ERROR",
                    payload: action.payload.key
                })
            }, 5000)
            return {
                ...state,
                errors: cloneDeep(state.errors)
            }
        case 'REMOVE_ERROR':
            const errorIndex = findIndex(state.errors, (e: { key: string }) => {
                return e.key == action.payload
            })
            if (errorIndex !== -1) {
                state.errors.splice(errorIndex, 1)
            }
            return {
                ...state,
                errors: cloneDeep(state.errors)
            }
        case 'SET_EVALUATION':
            return {
                ...state,
                newEvaluation: {
                    ...state.newEvaluation,
                    ...action.payload
                }
            }
        case 'SET_BREADCRUMBS':
            return {
                ...state,
                breadcrumbs: action.payload
            };
        case 'SET_NEW_PROJECT_DATA_ACTION':
            return {
                ...state,
                newProject: {...state.newProject, ...action.payload}
            };
        case 'GET_PROJECT':
            return {
                ...state,
                project: action.payload
            }
        case 'GET_PROJECTS':
            const projects: Project[] = orderBy(action.payload, (project: Project) => project.id, 'desc')
            const publicProjects = projects.filter((project: Project) => project.type == 'public')
            const privateProjects = projects.filter((project: Project) => project.type == 'private')
            const sharedProjects = projects.filter((project: Project) => project.type == 'shared')
            return {
                ...state,
                projects: privateProjects,
                breadcrumbs: [],
                publicProjects: publicProjects,
                sharedProjects: sharedProjects,
            };
        case 'GET_NOTIFICATIONS':
            action.payload.forEach((notification: Notification) => {
                if (notification.task === 'project') {
                    let foundProject: Project | undefined = state.projects ? state.projects.find((proj: Project) => proj.id === notification.id) : undefined;
                    if (foundProject) {
                        //@ts-ignore
                        foundProject.status = notification.status;
                        //@ts-ignore
                        foundProject.number_of_images = notification.number_of_images

                    }
                } else if (notification.task === 'evaluation') {
                    if (state.project) {
                        let foundEvaluation: EvaluationModel | undefined = state.project.evaluations ? state.project.evaluations.find((evaluation: EvaluationModel) => evaluation.id === notification.id) : undefined;
                        if (foundEvaluation) {
                            //@ts-ignore
                            foundEvaluation.status = notification.status
                            //@ts-ignore
                            foundEvaluation.extracted_images = notification.extracted_images
                        }
                    }
                }
            })
            return {
                ...state,
                notifications: state.notifications.concat(action.payload),
                projects: cloneDeep(state.projects),
                project: cloneDeep(state.project)
            };
        case 'SET_NOTIFICATIONS':
            return {
                ...state,
                notifications: action.payload
            };
        case 'ADD_NOTIFICATIONS':
            return {
                ...state,
                notifications: [action.payload, ...state.notifications]
            };
        case 'REMOVE_NOTIFICATIONS':
            return {
                ...state,
                notifications: []
            }
        case 'SET_DATA_PROVIDER':
            return {
                ...state,
                newProject: {...state.newProject, dataProvider: action.payload},
            };
        case 'SET_PROJECT_NAME':
            return {
                ...state,
                newProject: {...state.newProject, name: action.payload},
            };
        case 'SET_TASK':
            return {
                ...state,
                newProject: {...state.newProject, task: action.payload},
            };
        //s3, gcs
        case 'SET_IMAGES_PATH':
            return {
                ...state,
                newProject: {...state.newProject, imagesPath: action.payload},
            };
        case 'SET_GROUND_TRUTHS_PATH':
            return {
                ...state,
                newProject: {...state.newProject, groundTruthsPath: action.payload},
            };
        case 'SET_DETECTIONS_PATH':
            return {
                ...state,
                newProject: {...state.newProject, detectionsPath: action.payload},
            };
        case 'SET_DETECTIONS_METADATA_FORMAT':
            return {
                ...state,
                newProject: {...state.newProject, detectionsMetadataFormat: action.payload},
            };
        case 'SET_CLASSES_PATH':
            return {
                ...state,
                newProject: {...state.newProject, classesTxtPath: action.payload},
            };
        //deeplake
        case 'SET_CLASSES':
            return {
                ...state,
                newProject: {...state.newProject, classes: action.payload},
            };
        case 'SET_DETECTIONS_BOXES_KEY':
            return {
                ...state,
                newProject: {...state.newProject, detectionsBoxesKey: action.payload},
            };
        case 'SET_DEEPLAKE_TOKEN':
            return {
                ...state,
                newProject: {...state.newProject, deeplakeToken: action.payload},
            };
        case 'SET_DETECTIONS_LABELS_KEY':
            return {
                ...state,
                newProject: {...state.newProject, detectionsLabelsKey: action.payload},
            };
        case 'SET_DETECTIONS_SCORES_KEY':
            return {
                ...state,
                newProject: {...state.newProject, detectionsScoreKey: action.payload},
            };
        case 'SET_GROUND_TRUTHS_LABELS_KEY':
            return {
                ...state,
                newProject: {...state.newProject, groundTruthsLabelsKey: action.payload},
            };
        case 'SET_GROUND_TRUTHS_BOXES_KEY':
            return {
                ...state,
                newProject: {...state.newProject, groundTruthsBoxesKey: action.payload},
            };
        case 'SET_DATASET':
            return {
                ...state,
                newProject: {...state.newProject, dataSet: action.payload},
            };
        case 'SET_MODEL_NAME':
            return {
                ...state,
                newProject: {...state.newProject, modelPath: action.payload},
            };
        case 'SET_EVALUATION_DATA_PROVIDER':
            return {
                ...state,
                newEvaluation: {...state.newEvaluation, dataProvider: action.payload},
            };
        case 'SET_EVALUATION_NAME':
            return {
                ...state,
                newEvaluation: {...state.newEvaluation, name: action.payload},
            };
        case 'SET_EVALUATION_DEEPLAKE_TOKEN':
            return {
                ...state,
                newEvaluation: {...state.newEvaluation, deeplakeToken: action.payload},
            };
        case 'SET_EVALUATION_PROJECT_ID':
            return {
                ...state,
                newEvaluation: {...state.newEvaluation, projectId: action.payload},
            };
        case 'SET_EVALUATION_DATA_PATH':
            return {
                ...state,
                newEvaluation: {...state.newEvaluation, dataPath: action.payload},
            };
        case 'PROJECT_FILES_UPLOADED':
            return {
                ...state,
                newProject: {
                    ...state.newProject, ...{
                        imagesPath: action.payload.images_path,
                        groundTruthsPath: action.payload.ground_truths_path,
                        detectionsPath: action.payload.detections_path,
                    }
                }
            }
        case 'EVALUATION_FILES_UPLOADED':
            return {
                ...state,
                newEvaluation: {
                    ...state.newEvaluation, ...{
                        dataPath: action.payload.data_path,
                    }
                }
            }
        case 'GET_EVALUATION':

            return {
                ...state,
                evaluation: action.payload,
                clusters: action.payload.clusters_data_set
            }
        case 'GET_RECLUSTERS':
            return {
                ...state,
                clusters: action.payload.clusters_data,
                similarDataset: null
            }
        case 'RESET_ZOOM':
            return {
                ...state,
                //@ts-ignore
            }
        case 'FILTER_CLUSTER':
            return {
                ...state,
                clusters: action.payload.clusters_data,
                similarDataset: null
            }
        case 'GET_SIMILARS':
            return {
                ...state,
                similarDataset: action.payload
            }
        default:
            return state;
    }
};
export default reducer