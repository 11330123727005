import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { default as MuiButton } from "@mui/material/Button";

type Props = {
    text: string;
    onClick: (a: number) => void;
    icon?: JSX.Element;
    disabled?: boolean;
    onKeypress?: any;
    loading?: boolean;
    outline?: boolean;
    toggle?: boolean;
};

function Button({
    text,
    onClick,
    icon,
    outline = false,
    toggle = false,
    loading = false,
    disabled,
    ...rest
}: Props) {
    useEffect(() => {
        setLoading(loading);
    }, [loading]);
    let [buttonLoading, setLoading] = useState(loading);
    const { t } = useTranslation();
    return (
        <MuiButton
            disableFocusRipple
            size="small"
            disableRipple
            variant={outline ? "outlined" : "contained"}
            disabled={disabled || buttonLoading}
            startIcon={icon ?? null}
            sx={
                outline
                    ? {
                          textTransform: "none",
                          fontWeight: 400,
                          lineHeight: 1.5,
                          fontSize: 14,
                          color: "rgba(160, 27, 103,0.75)",
                          boxShadow: "none",
                          borderColor: "#CA95B3",
                          backgroundColor: toggle
                              ? "rgba(160, 27, 103, 0.075)"
                              : "transparent",
                          "&:hover": {
                              backgroundColor: "rgba(160, 27, 103, 0.075)",
                              borderColor: "#CA95B3",
                          },
                      }
                    : {
                          textTransform: "none",
                          fontWeight: 400,
                          lineHeight: 1.5,
                          fontSize: 14,
                          backgroundColor: "rgba(160,27,103)",
                          color: "rgba(255,255,255)",
                          boxShadow: "none",
                          "&:hover": {
                              backgroundColor: "rgba(90,14,84)",
                              boxShadow: "none",
                          },
                          marginTop: 1.5,

                      }
            }
            onClick={async () => {
                if (!buttonLoading) {
                    setLoading(true);
                    await onClick(1);
                    setLoading(false);
                }
            }}
            {...rest}
        >
            {buttonLoading ? (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    Loading...
                </div>
            ) : (
                t(text)
            )}

        </MuiButton>
    );
}

export default Button;
