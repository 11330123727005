import React from 'react';
import './styles.scss';
import Input from "../input";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

export type Props = {
    placeholder: string | string[];
    onChange: (key: string, value: any) => void;
    size?: string;
    type?: string;
    options?:
        | { value: string; label: string }[]
        | { label: string; options: { value: string; label: string }[] }[];
    onKeyPress?: any;
    title: string;
    value?: any;
    subtitle?: string;
    name: string;
    validation?: string;
    info?: {
        text?: string;
        url?: string;
    };
    disabled?: boolean;
    extraActionOnChange?: () => void;
};

function InputWithTitle({
    placeholder,
    subtitle,
    onChange,
    type = "text",
    size = "large",
    options,
    onKeyPress,
    title,
    name,
    value,
    validation,
    info,
    disabled,
    extraActionOnChange,
}: Props) {
    return (
        <Box>
            <Box display="flex" flexDirection="row">
                <Typography
                    fontSize={14}
                    fontWeight={"500"}
                    paddingBottom={0.1}
                    marginTop={1.5}
                    paddingRight={1}
                    color={"rgba(0, 0, 0, 0.87)"}
                >
                    {title}
                    {subtitle && (
                        <h4 className={"input-with-image-subtitle-class"}>
                            {subtitle}
                        </h4>
                    )}
                </Typography>
                {info && (
                    <Tooltip arrow title={info.text} placement="top">
                        <IconButton
                            disableFocusRipple
                            disableRipple
                            sx={{
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: "initial",
                                },
                            }}
                        >
                            <img
                                width={16}
                                height={16}
                                alt={"info icon"}
                                src={`/info.svg`}
                            />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            <Input
                placeholder={placeholder}
                validation={validation}
                onChange={onChange}
                type={type}
                name={name}
                size={size}
                disabled={disabled}
                // @ts-ignore
                options={options}
                onKeyPress={onKeyPress}
                value={value}
                extraActionOnChange={extraActionOnChange}
            ></Input>
        </Box>
    );
}

export default InputWithTitle;
