import { doRequest } from "../helpers/request_helper";
import { cloneDeep, differenceWith, filter, isEqual, remove } from "lodash";
import store from "../store";
import Notification from "../types/notification";

const setBreadcrumbs = (breadcrumbs: any[]) => {
  return {
    type: 'SET_BREADCRUMBS',
    payload: breadcrumbs,
  };
}

const statusTexts = {
  'failure': "failed",
  'started': 'started',
  'finished': 'finished'
}

const getNotificationsAction = async (runningTasks: [] = []) => {
  const projectIds = runningTasks.filter((rt: any) => rt.task === 'project').map((rt: any) => rt.id)
  const evaluationIds = runningTasks.filter((rt: any) => rt.task === 'evaluation').map((rt: any) => rt.id)
  const response: Response = await doRequest('get', `api/v1/user/running-tasks?projects=${projectIds.join(',')}&evaluations=${evaluationIds.join(',')}`,)
  if (response && response.status == 200) {
    const responseBody = await response.json()
    let changedStatus: Notification[] = [];
    if (runningTasks.length) {
      changedStatus = differenceWith(responseBody, runningTasks, isEqual);
      changedStatus = filter(changedStatus, (cs) => {
        return cs.status === 'finished' || cs.status === 'failure'
      })
    }
    if (changedStatus.length) {

      setTimeout(() => {
        removeNotifications(changedStatus);
      }, 5000)

      store.dispatch({
        type: 'GET_NOTIFICATIONS',
        payload: changedStatus.map((notification) => {
          return {
            ...notification,
            //@ts-ignore
            message: ` has ${statusTexts[notification.status]} ${notification.status == 'failure' ? 'with ' + notification.message : ''}`
          }
        })
      })
    }
    setTimeout(() => {
      getNotificationsAction(responseBody)
    }, 10000)

  } else {
    setTimeout(() => {
      getNotificationsAction()
    }, 2000)

  }
  return {
    type: 'dummy',
    payload: []
  }


}
const removeNotifications = (changedStatus: Array<Notification>) => {
  const notifications = cloneDeep(store.getState().notifications);
  changedStatus.forEach((notification: Notification) => {
    //@ts-ignore
    remove(notifications, (currentObject: Notification) => {
      return currentObject.id === notification.id && currentObject.task === notification.task;
    })
  });
  store.dispatch({
    type: 'SET_NOTIFICATIONS',
    payload: notifications
  });
}

export {
  setBreadcrumbs,
  getNotificationsAction,
  removeNotifications
}

