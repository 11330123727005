const addNotification = (notification_text: string, name: string, status: string) => {
    return {
        type: 'ADD_NOTIFICATIONS',
        payload: {name: name, message: notification_text, status: status}
    }
}


const removeNotifications = () => {
    return {
        type: 'REMOVE_NOTIFICATIONS',
        payload: null
    }
}

export {
    addNotification,
    removeNotifications
}